import {produce} from 'immer';
import _ from 'lodash';
import {USER_SET_IS_LOGOUT} from '../../pages/Login/actions';
import {
  STORAGE_MNG_ACTIONS_CHILDREN,
  STORAGE_MNG_ACTIONS_CLOSE,
  STORAGE_MNG_ACTIONS_CONTENTS,
  STORAGE_MNG_ACTIONS_CONTENTS_IS_LOADING,
  STORAGE_MNG_SET_ACTION_TYPES,
  STORAGE_MNG_ACTIONS_IS_LOADING,
  STORAGE_MNG_ACTIONS_PARENT,
  STORAGE_MNG_ACTIONS_SHOW,
  STORAGE_MNG_ACTIONS_USER_PERMISSIONS,
  STORAGE_MNG_ACTIONS_USER_PERMISSIONS_IS_LOADING,
  STORAGE_MNG_ACTIONS_USER_PERMISSIONS_WITHOUT_ROOTS,
  STORAGE_MNG_MY_ACTIONS_SHOW,
  STORAGE_MNG_MY_ACTIONS_CLOSE,
  STORAGE_MNG_MY_ACTIONS_CONTENTS_IS_LOADING,
  STORAGE_MNG_MY_ACTIONS_ACTIVE_CONTENTS,
  STORAGE_MNG_ACTIVE_FS_ROOTS,
  STORAGE_MNG_ACTIVE_FS_ROOTS_IS_LOADING,
  STORAGE_MNG_ADD_TO_NAVIGATION_HISTORY,
  STORAGE_MNG_ADD_TO_NAVIGATION_HISTORY_FORWARD,
  STORAGE_MNG_ALL_OFFICES_ACTION_STATUS_BIT,
  STORAGE_MNG_SET_AVAILABLE_ACTIONS_BY_SOURCES,
  STORAGE_MNG_CHANGE_CHECKED_ITEMS,
  STORAGE_MNG_CHANGE_NAVIGATION_HISTORY,
  STORAGE_MNG_CHANGE_NAVIGATION_HISTORY_FORWARD,
  STORAGE_MNG_CHANGE_OPENED_FOLDERS,
  STORAGE_MNG_CHANGE_OPENED_FOLDERS_IN_MODAL,
  STORAGE_MNG_CLEAR_ALL,
  STORAGE_MNG_CLEAR_ALL_FOR_DOWNLOAD_MNG,
  STORAGE_MNG_CLIENT_LIST_SET,
  STORAGE_MNG_CLIENT_NAME_LOADING,
  STORAGE_MNG_CLIENT_NAME_SET,
  STORAGE_MNG_CONNECT_DRIVE,
  STORAGE_MNG_CONTENT_PERMISSIONS_SHOW_MODAL,
  STORAGE_MNG_COPY_SHOW_MODAL,
  STORAGE_MNG_COPY_TO_QOD_SHOW_MODAL,
  STORAGE_MNG_DISCONNECT_DRIVE,
  STORAGE_MNG_DONE_SEARCH,
  STORAGE_MNG_FETCH_FOLDER,
  STORAGE_MNG_FETCH_ROOTS,
  STORAGE_MNG_FILE_SETTINGS_ALLOW_READING,
  STORAGE_MNG_FINAL_DESTINATION,
  STORAGE_MNG_FINAL_DESTINATION_IS_LOADING,
  STORAGE_MNG_FINAL_DESTINATION_RESERVED,
  USE_STORAGE_MNG_FINAL_DESTINATION_RESERVED,
  STORAGE_MNG_FOLDER_CHILDREN_RECEIVE,
  STORAGE_MNG_FS_RUNNER_ACTION_STATUS_BIT,
  STORAGE_MNG_INGEST_OPERATION_TYPES_IS_LOADING,
  STORAGE_MNG_INGEST_SHOW_MODAL,
  STORAGE_MNG_INITIAL_CONFIRMATION_DELETION,
  STORAGE_MNG_INSTANT_SCAN_CLOSE,
  STORAGE_MNG_INSTANT_SCAN_START,
  STORAGE_MNG_INSTANT_SCAN_STOP,
  STORAGE_MNG_IS_LOADING,
  STORAGE_MNG_IS_SEARCHING,
  STORAGE_MNG_LINK_IN_BURBANK_SHOW_MODAL,
  STORAGE_MNG_LINK_TO_MEMFIS_WO_IS_LOADING,
  STORAGE_MNG_LINK_TO_MEMFIS_WO_IS_SAVED,
  STORAGE_MNG_LINK_TO_MEMFIS_WO_IS_SAVING,
  STORAGE_MNG_LINK_TO_MEMFIS_WO_SHOW_MODAL,
  STORAGE_MNG_LINK_TO_WO_LOAD_CLIENTS_BY_FNAME,
  STORAGE_MNG_LINK_TO_WO_NEW_WO_BY_FNAME,
  STORAGE_MNG_LOAD_SETTINGS,
  STORAGE_MNG_LOCK_AND_SERVICES_STATUSES,
  STORAGE_MNG_LOCK_AND_SERVICES_STATUSES_IS_LOADING,
  STORAGE_MNG_MACHINE_RUNNER_ACTION_STATUS_BIT,
  STORAGE_MNG_CHECKSUM_GEN,
  STORAGE_MNG_CHECKSUM_SHOW_MODAL,
  STORAGE_MNG_CHECKSUM_MD5_SHOW_MODAL,
  STORAGE_MNG_CHECKSUM_SHA1_SHOW_MODAL,
  STORAGE_MNG_MODAL_IS_LOADING,
  STORAGE_MNG_SET_MODAL_STEP,
  STORAGE_MNG_MOVE_SHOW_MODAL,
  STORAGE_MNG_SCHEDULED_PAUSE_SHOW_MODAL,
  STORAGE_MNG_NAV_INTO_FETCH_FOLDER,
  STORAGE_MNG_NAV_INTO_IS_LOADING,
  STORAGE_MNG_NEW_DIRECTORY_SHOW_MODAL,
  STORAGE_MNG_OFFICE_ACTION_STATUS_BIT,
  STORAGE_MNG_PREDICTIVE_STATES,
  STORAGE_MNG_PREDICTIVE_STATES_IS_LOADING,
  STORAGE_MNG_READ_FILE_START,
  STORAGE_MNG_READ_FILE_STOP,
  STORAGE_MNG_REFRESH_SEL_CONTENT_IN_CUR_FOLDER,
  STORAGE_MNG_REFRESH_SEL_ROOT,
  STORAGE_MNG_REMOVE_FROM_TEMP_DIRS,
  STORAGE_MNG_RENAME_SHOW_MODAL,
  STORAGE_MNG_RESET_NAVIGATION_HISTORY_FORWARD,
  STORAGE_MNG_SAVE_SETTINGS,
  STORAGE_MNG_SCRIPT_RUNNER_ACTION_STATUS_BIT,
  STORAGE_MNG_SEARCH_CHANGE_INPUT,
  STORAGE_MNG_SET_CONTENT_PERMISSIONS_SUCCESS,
  STORAGE_MNG_SET_CURRENT_VIEW,
  STORAGE_MNG_SET_HIDE_TEMP_FILES,
  STORAGE_MNG_SET_FOLDERS_IN_MODAL,
  STORAGE_MNG_SET_LOADING,
  STORAGE_MNG_SHOW_ADV_SEARCH_MODAL,
  STORAGE_MNG_SHOW_APLUS_SETTINGS,
  STORAGE_MNG_SHOW_AVAILABLE_DRIVES_MODAL,
  STORAGE_MNG_SHOW_ENCRYPT_MODAL,
  STORAGE_MNG_SHOW_MANUALLY_RECONCILE_MODAL,
  STORAGE_MNG_SHOW_SELECT_FOLDER_MODAL,
  STORAGE_MNG_SINGLE_ENCRYPT_OR_DECRYPT,
  STORAGE_MNG_TEMP_DIRS_ADDED,
  STORAGE_MNG_TEMP_DIRS_DELETED,
  STORAGE_MNG_TOGGLE_SHOW_RENAME_AMAZON_FILE_MODAL,
  STORAGE_MNG_TOOLBAR_CHANGE_INPUT_NAV,
  STORAGE_MNG_TOOLBAR_IS_REFRESHING_INPUT_NAV,
  STORAGE_MNG_SWITCH_SETTINGS_MODAL,
  STORAGE_MNG_SWITCH_MY_ACTIONS_MODAL,
  STORAGE_MNG_LKFS_SWITCH_MODAL,
  STORAGE_MNG_LKFS_UPDATE_AMB,
  STORAGE_MNG_LKFS_GOTO_SCREEN,
  STORAGE_MNG_LKFS_RESET,
  STORAGE_MNG_LKFS_UPDATE_WO_MEMFIS,
  STORAGE_MNG_LKFS_CHOOSE_DESTINATION,
  STORAGE_MNG_LKFS_SET_AUTO_WO_STATUS,
  STORAGE_MNG_LKFS_AUTO_CHOOSE_DESTINATION,
  STORAGE_MNG_LKFS_AUTO_UPDATE_AMB,
  STORAGE_MNG_ADD_FILE_TO_WO_SWITCH_MODAL,
  STORAGE_MNG_ADD_FILE_TO_WO_GOTO_SCREEN,
  STORAGE_MNG_ADD_FILE_TO_WO_CHANGE_STATUS,
  STORAGE_MNG_ADD_FILE_TO_WO_UPDATE_WO_MEMFIS,
  STORAGE_MNG_ADD_FILE_TO_WO_SET_ASSIGN_NEW_WO,
  STORAGE_MNG_ADD_FILE_TO_WO_SET_NEW_WO,
  STORAGE_MNG_ADD_FILE_TO_WO_CHOOSE_DESTINATION,
  STORAGE_MNG_ADD_FILE_TO_WO_CHOOSE_COPY_DESTINATION,
  STORAGE_MNG_ADD_FILE_TO_WO_AUTO_CHOOSE_DESTINATION,
  STORAGE_MNG_ADD_FILE_TO_WO_SET_AUTO_CHOOSE_DESTINATION,
  STORAGE_MNG_ADD_FILE_TO_WO_NEW_FOLDER_CHECKBOX_CHANGE,
  STORAGE_MNG_ADD_FILE_TO_WO_CREATE_QOD_PROXY_CHECKBOX_CHANGE,
  STORAGE_MNG_ADD_FILE_TO_WO_SEND_CAPTIONS_TO_QOD_CHECKBOX_CHANGE,
  STORAGE_MNG_ADD_FILE_TO_WO_CHANGE_DESTINATION_AVAILABLE,
  STORAGE_MNG_ADD_FILE_TO_WO_RESET_DESTINATION,
  STORAGE_MNG_LOAD_ACTION_PERMISSIONS,
  STORAGE_MNG_ADD_FILE_TO_WO_SET_SELECTED_ROOT,
  STORAGE_MNG_ADD_FILE_TO_WO_CLEAR,
  STORAGE_MNG_ADD_FILE_TO_WO_SET_SUFFIX,
  STORAGE_MNG_ADD_FILE_TO_WO_SET_SUFFIX_FILE,
  STORAGE_MNG_ADD_FILE_TO_WO_RESET_AMB,
  STORAGE_MNG_ADD_FILE_TO_WO_UPDATE_AMB,
  STORAGE_MNG_ADD_FILE_TO_WO_SET_RUN_LKFS_ANALYSYS,
  STORAGE_MNG_ADD_FILE_TO_WO_SET_DELIVERY_RENAME_AVAILABLE,
  STORAGE_MNG_MEDIA_INFO_SHOW_MODAL,
  STORAGE_MNG_SET_MEDIA_INFO,
  STORAGE_MNG_UPLOAD_TO_ASPERA_SHOW_MODAL,
  STORAGE_MNG_LOAD_FASPEX_ACCOUNTS,
  STORAGE_MNG_SET_QOD_INFO,
  STORAGE_MNG_SET_QOD_AVAILABLE_WOMEMFISES,
  STORAGE_MNG_SET_QOD_PROXIES,
  STORAGE_MNG_CREATE_SYMLINK_SHOW_MODAL,
  STORAGE_MNG_ADD_FILE_TO_WO_AUTO_SAVE_REPLACEMENT,
  STORAGE_MNG_SET_CURRENT_FOLDER_FOR_NESTED_TABLE,
  STORAGE_MNG_UNLINK_SHOW_MODAL,
  STORAGE_MNG_CHECKSUM_REPORT_TOGGLE_MODAL,
  STORAGE_MNG_CHANGE_SYMLINK_RELATIVE_STATUS,
  STORAGE_MNG_SET_IS_CREATE_SYMLINK_CHECKED,
  STORAGE_MNG_SET_REPLACEMENT_TYPE_VALUE,
  STORAGE_MNG_SET_ATTACHED_VOLUMES,
  STORAGE_MNG_TOGGLE_ACTION_PROGRESS,
  STORAGE_MNG_LOADING_ACTION_PROGRESS,
  STORAGE_MNG_SET_ACTION_PROGRESS,
  STORAGE_MNG_TOGGLE_ACTION_SUBSCRIBE_POPUP,
  STORAGE_MNG_TOGGLE_DELIVERY_RENAME_POPUP,
  STORAGE_MNG_RESET_COPY_MODAL_DATA,
  STORAGE_MNG_RESET_COPY_TO_QOD_MODAL_DATA,
  DIRECTORY_TREE_CHANGE_SEARCH_INPUT,
  DIRECTORY_TREE_IS_LOADING,
  DIRECTORY_TREE_FETCH_FOLDER,
  STORAGE_MNG_SET_ENABLE_ACTION_TO_WO,
  STORAGE_MNG_ACTIONS_REQUESTORS_SHOW,
  STORAGE_MNG_ADD_TO_WO_SAVING,
  STORAGE_MNG_ADD_TO_WO_INGEST_FILES,
  STORAGE_MNG_TOGGLE_ATTACH_TO_WO_MODAL,
  STORAGE_MNG_TOGGLE_ISSUE_SCREENSHOT_QOD_SHOW_MODAL,
  STORAGE_MNG_ACTIONS_SUBSCRIBERS_SHOW,
  STORAGE_MNG_ADD_FILE_TO_WO_SET_DISNEY_INFO,
  STORAGE_MNG_ADD_FILE_TO_WO_SET_EMPTY_DISNEY_INFO,
  STORAGE_MNG_CAPTIONS_SHOW_MODAL,
  STORAGE_MNG_PSE_SHOW_MODAL,
  STORAGE_MNG_PSE_IS_LOADING,
  STORAGE_MNG_PSE_HDR_FILE_TYPE,
  STORAGE_MNG_ALERT_SHOW_MODAL,
  STORAGE_MNG_ALERT_MODAL_DATA,
  STORAGE_MNG_METAFIER_SHOW_MODAL,
  //STORAGE_MNG_EDGE_PASS_SHOW_MODAL,
  //STORAGE_MNG_SCREEN_TO_QOD_SHOW_MODAL,
  STORAGE_MNG_LKFS_SET_FRAME_RATE_VALUE,
  STORAGE_MNG_FRAME_RATE_SHOW_MODAL,
  STORAGE_MNG_SET_FRAME_RATE,
  STORAGE_MNG_FRAME_RATE_UNCHECK_LKFS,
  STORAGE_MNG_CLEAR_FRAME_RATE,
  STORAGE_MNG_SET_ERROR_NUMBER_FRAME_RATE,
  STORAGE_MNG_WO_STEPS,
  STORAGE_MNG_WO_STEPS_SHOW_MODAL,
  STORAGE_MNG_CLEAR_WO_STEPS,
  STORAGE_MNG_ACTIVATE_WO_STEP,
  STORAGE_MNG_WO_STEPS_EMPTY,
  STORAGE_MNG_WO_STEPS_IS_SAVING,
  STORAGE_MNG_WO_STEPS_ADD_TO_WO_SHOW_MODAL,
  STORAGE_MNG_WO_STEPS_ADD_TO_WO_IS_NEXT,
  STORAGE_MNG_WO_STEPS_ADD_TO_WO_IS_BACK,
  STORAGE_MNG_HDR_REPORT_SWITCH_MODAL
} from './actions';
import {
  DISPLAY_CATEGORY_ROOTS, DISPLAY_CATEGORY_FOLDER, DISPLAY_CATEGORY_SEARCH_RESULTS, DISPLAY_CATEGORY_ACTION_CONTENTS,
  DISPLAY_CATEGORY_INIT_CONFIRM_DELETION,
  ALL_FILTERS,
  DefaultBooleanFilters,
  VIEW_DEFAULT,
  PREDICTIVE_STATE_DISAPPEARED
} from './constants';

const addChildrenIntoFolder = (items, folder, children, allParents) => {
  if (Object.isExtensible(items)) {
    let folderForChildren = items.find(i => i.fsRootID === folder.fsRootID && i.contentID === allParents[0].contentID &&
      (i.predictiveStateID || 0) === (allParents[0].predictiveStateID || 0) && (!i.parents || !i.parents.length));
    for (let index = 1; index < allParents.length; index++) {
      if (!folderForChildren || !folderForChildren.children) {
        break;
      }
      folderForChildren = folderForChildren.children.find(i => i.contentID === allParents[index].contentID);
    }
    if (folderForChildren) {
      folderForChildren.children = children;
    }
    return items;
  } else {
    console.error('TypeError: Object is not extensible. ' +
      'For `items` argument of this function, it is recommended to use `draft` proxy object from `immer` library.');
    return [];
  }
};

const isActionTypeForAddFileToWO = type => type.indexOf('STORAGE_MNG_ADD_FILE_TO_WO_') !== -1;

const getModalStateName = actionType => isActionTypeForAddFileToWO(actionType) ? 'addFileToWoModalState' : 'LKFSModalState';

const initialState = {
  actionContentsIsLoading: false,
  actionTypes: null,
  actionsTotal: null,
  actions: null,
  myActions: null,
  hasActiveMyActions: false,
  actionsChildren: {},
  actionsFilters: null,
  actionsRequestorsMap: null,
  actionsIsLoading: false,
  actionsParent: {},
  actionsUserPermissions: [],
  actionsUserPermissionsIsLoading: false,
  actionsUserPermissionsWithRoots: {},
  addFileToWoModalState: {
    currentCopyDestination: { key: '' },
    currentDestination: { key: '' },
    currentDestinationShort: { key: '' },
    currentWOMemfis: '',
    newWOMemfis: '',
    initialDestination: { key: '' },
    initialWOMemfis: '',
    isAutoWO: false,
    isInitialScreen: false,
    isIngestScreen: false,
    isInnerFileIngestScreen: false,
    isFilesScreen: false,
    selectedRoot: { value: ''},
    isDestinationAvailable: false,
    isDestinationChanged: false,
    isDirectoryAsRootChecked: null,
    isDirectoryAsRootDisabled: false,
    isCreateQODProxyChecked: false,
    isSelectingDestination: false,
    isSelectingWOMemfis: false,
    isWOMemfisLoading: false,
    proxyCopyAvailable: false,
    showFileSelection: false,
    showIngestSettings: false,
    ingestFiles: null,
    ingestOperationTypes: null,
    showCaptionsSendToQod: false,
    checkedCaptionsSendToQod: true,
    ingestCopyFolder: null,
    show: false,
    replacementData: {},
    addFileToWOSuffix: false,
    isAssignNewWo: false,
    default_symlink_root_directory_body: null,
    default_symlink_root_directory_tail: null,
    AMBCurrent: 0,
    AMBInitial: 0,
    runLKFSanalysis: false,
    isCreateSymlinkChecked: false,
    replacementTypeValue: '',
    deliveryRenameAvailable: {value: null, woMemfis: null},
  },
  allFilters: ALL_FILTERS,
  aplusSettings: null,
  appliedNavigationString: '',
  appliedNavigationStringForMacOS: '',
  appliedSearchString: '',
  attachedVolumes: false,
  availableDrivesModalData: null,
  availableFsRoots: [],
  availableFsRootsIsLoading: false,
  availableManualMemfisWOs: null,
  availableManualMemfisWOsIsLoading: false,
  clientByFNameForWO: null,
  newWoByFName: null,
  availableActionsBySources: {},
  checkedItems: [],
  clientNameIsLoading: false,

  copyModalData: null,
  copyToQodModalState: {
    copyToQodInfo: null,
    copyToQodInfoList: [],
    pseAnalyseInfo: {},
    availableWOmemfises: [],
    ownProxyName: null,
  },
  woSteps: null,
  isWoStepsEmpty: false,
  showWoStepsAddToWo: false,
  woStepsIsShowModal: false,
  woStepsIsSaving: false,
  isWoStepsAddToWoNext: false,
  isWoStepsAddToWoBack: false,
  enableAddToWO: true,
  enableLinkToWO: true,
  disneySelection: false,
  disneySelectionWO: '',
  addToWoFileRootFolderWO: '',

  currentAction: null,
  currentFolder: null,
  currentView: VIEW_DEFAULT,
  hideTempFiles: false,
  displayCategory: '',
  displayedItems: [],
  displayedItemsInPopup: [],
  extensionSettings: undefined,
  faspexAccounts: [],
  fileSettingsAllowReading: {},
  finalDestinationData: null,
  finalDestinationDataReserved: null,
  useFinalDestinationDataReserved: false,
  finalDestinationIsLoading: false,
  folderExplorerFolders: [],
  foldersInModal: [],
  fsRoots: [],
  ingestOperationTypes: null,
  ingestOperationTypesIsLoading: false,
  ingestWorkOrder: null,
  ingestFromLinkToWo: false,
  addToWoIngestData: null,
  inputNavigationString: '',
  inputNavigationStringForMacOS: '',
  inputSearchString: '',
  instantScanActionResult: null,
  instantScanning: false,
  instantScanObject: null,
  isLoadingModal: false,
  isReadingFile: false,
  isSettingsModalOpen: false,
  isMyActionsModalOpen: false,
  isShowContentPermissionsModal: false,
  isShowCopyModal: false,
  isShowCopyToQodModal: false,
  isShowCreateSymlinkModal: false,
  isShowEncryptModal: false,
  isShowMediaInfoModal: false,
  isShowMoveModal: false,
  isShowScheduledPauseModal: false,
  scheduledPauseSelectedAction: null,
  allowedMoveActions: [],
  allowedCopyActions: [],
  isShowNewFolderModal: false,
  isShowRenameModal: false,
  isShowSelectFolderModal: false,
  isShowShowRenameAmazonFileModal: false,
  isShowUnlinkModal: false,
  isShowUploadToAsperaModal: false,
  isSingleLinking: false,
  isVisibleAdvSearchModal: false,
  linkInBurbankModalItem: null,
  linkToMemfisWOIsSaving: false,
  LKFSModalState: {
    AMBCurrent: 0,
    AMBInitial: 0,
    currentDestination: { key: '' },
    currentWOMemfis: '',
    initialDestination: { key: '' },
    initialWOMemfis: '',
    isAutoWO: false,
    isSelectingDestination: false,
    isSelectingWOMemfis: false,
    isWOMemfisLoading: false,
    show: false,
    frameRate: null,
    dbFrameRate: null,
    errorType: null,
    newFrameRate: null,
    isUserFrameRate: false,
    isLKFSSubmit: true,
    fileFrameRates: [],
    isErrorNumberFrameRate: false,
    error_qc_project: false,
    is_enable: false
  },
  loaders: {
    addFileToWO: false,
    aplusSettingsLoading: false,
    buildingZip: false,
    connectDrive: false,
    copyFsItem: false,
    deleteWithApproval: false,
    disconnectDrive: false,
    executeAllOfficesAction: false,
    executeFsRunnerAction: false,
    executeMachineRunnerAction: false,
    executeOfficeAction: false,
    executeScriptRunnerAction: false,
    fileSettingsAllowReading: false,
    getCopyData: false,
    getEncryptOrDecryptPassword: false,
    refreshSelectedContent: false,
    setContentGroup: false,
    setContentPermissions: false,
    singleEncryptOrDecrypt: false,
    uploadingFile: false,
    instantScanningSubFolder: false,
  },
  loading: false,
  unknownFsRoots: null,
  lockAndServicesStatuses: {
    PredictiveStateEnabled: true,
    FsRootLockStatuses: []
  },
  lockAndServicesStatusesIsLoading: false,
  manuallyReconcileData: null,
  mediaInfo: {},
  checksumOnlyType: false,
  md5ChecksumOpening: false,
  sha1ChecksumOpening: false,
  md5ChecksumIsGenerating: false,
  sha1ChecksumIsGenerating: false,
  checksumIsShowModal: false,
  captionsIsShowModal: false,
  pseType: null,
  pseFile: null,
  pseCompanion: null,
  pseIsShowModal: false,
  metafierIsShowModal: false,
  //edgePassIsShowModal: false,
  //screenToQodIsShowModal: false,
  frameRateIsShowModal: false,
  alertIsShowModal: false,
  alertModalType: "",
  alertModalMessage: "",
  isPseHdrFileType: null,
  pseWoMemfis: null,
  modalState: null,
  navigationHistory: [],
  navigationHistoryForward: [],
  navigationIntoIsLoading: false,
  openedFolders: {},
  openedFoldersInModal: {},
  predictiveStates: [],
  predictiveStatesIsLoading: false,
  refreshingNavInput: false,
  regExpAllowReadingFile: null,
  renameModalData: null,
  searching: false,
  selectedFolder: {},
  settings: {},
  sourceOfActionsRequest: '',
  tempDirectories: {
    added: {},
    deleted: {}
  },
  usedActionsFilters: {},
  usedFilters: {
    ...DefaultBooleanFilters
  },
  zipFileSetting: null,

  isShowChecksumReportModal: false,
  isShowIssueScreenshotModal: false,
  isShowHdrReportModal: false,

  actionInProgress: null,
  loadingActionProgress: false,

  deliveryRenamePopupData: null,

  isShowAttachToWoModal: false,

  actionWatchInProgress: null,

  fileItemForSpecRename: null
};

export default function (state = initialState, action) {
  switch (action.type) {
    case STORAGE_MNG_CLEAR_ALL:
    case USER_SET_IS_LOGOUT: {
      return {...initialState};
    }

    case STORAGE_MNG_CLEAR_ALL_FOR_DOWNLOAD_MNG: {
      return {...initialState, ..._.pick(state, [
        'actionsUserPermissions',
        'actionsUserPermissionsWithRoots',
        'availableFsRoots',
        'fsRoots'
        ])};
    }

    case STORAGE_MNG_IS_LOADING: {
      return {...state, loading: action.loading};
    }

    case STORAGE_MNG_SET_ACTION_TYPES: {
      return {...state, actionTypes: action.types};
    }

    case STORAGE_MNG_FETCH_ROOTS: {
      const checkedItems = [];
      if (action.checkRootWithId) {
        const checkedItem = action.roots.find((root) => root.fsRootID === action.checkRootWithId);
        if (checkedItem) {
          checkedItems.push(checkedItem);
        }
      }
      return {
        ...state,
        appliedNavigationString: action.navString,
        appliedNavigationStringForMacOS: action.navStringForMacOS,
        appliedSearchString: initialState.appliedSearchString,
        availableActionsBySources: initialState.availableActionsBySources,
        checkedItems,
        currentAction: initialState.currentAction,
        currentFolder: initialState.currentFolder,
        displayCategory: DISPLAY_CATEGORY_ROOTS,
        displayedItems: action.roots,
        inputNavigationString: action.navString,
        inputNavigationStringForMacOS: action.navStringForMacOS,
        inputSearchString: initialState.inputSearchString,
        loading: false,
        tempDirectories: initialState.tempDirectories,
        usedFilters: action.filters,
      };
    }

    case STORAGE_MNG_FETCH_FOLDER: {
      let checkedItems = [];
      let fileItemForSpecRename;
      const {checkItemsWithIdOrName, skipDisappearedItemsToCheck, fileForSpecRename} = action;
      if (Array.isArray(checkItemsWithIdOrName) && action.children) {
        checkedItems = [
          ...action.children.filter((child) => (checkItemsWithIdOrName.includes(child.contentID) ||
                                               checkItemsWithIdOrName.includes(child.name)) &&
                                               (!skipDisappearedItemsToCheck || child.predictiveStateID !== PREDICTIVE_STATE_DISAPPEARED)
          )
        ];
      }
      if (fileForSpecRename && action.children) {
        fileItemForSpecRename = action.children.find(i => i.fsRootID === fileForSpecRename.fsRootID && i.contentID === fileForSpecRename.contentID && !i.isDirectory);
      }
      return {
        ...state,
        appliedNavigationString: action.navString,
        appliedNavigationStringForMacOS: action.navStringForMacOS,
        appliedSearchString: initialState.appliedSearchString,
        availableActionsBySources: initialState.availableActionsBySources,
        checkedItems,
        currentAction: initialState.currentAction,
        currentFolder: action.content,
        displayCategory: DISPLAY_CATEGORY_FOLDER,
        displayedItems: action.children || initialState.displayedItems,
        inputNavigationString: action.navString,
        inputNavigationStringForMacOS: action.navStringForMacOS,
        inputSearchString: initialState.inputSearchString,
        loading: false,
        tempDirectories: initialState.tempDirectories,
        usedFilters: action.filters,
        fileItemForSpecRename,
      };
    }

    case STORAGE_MNG_SET_CURRENT_FOLDER_FOR_NESTED_TABLE: {
      return {
        ...state,
        currentFolder: action.folder,
      }
    }

    case STORAGE_MNG_IS_SEARCHING: {
      return {
        ...state,
        searching: action.searching
      };
    }

    case STORAGE_MNG_DONE_SEARCH: {
      return {
        ...state,
        appliedNavigationString: action.navString,
        appliedNavigationStringForMacOS: action.navStringForMacOS,
        appliedSearchString: action.inputSearchString || initialState.appliedSearchString,
        availableActionsBySources: initialState.availableActionsBySources,
        checkedItems: initialState.checkedItems,
        currentAction: initialState.currentAction,
        currentFolder: initialState.currentFolder,
        displayCategory: DISPLAY_CATEGORY_SEARCH_RESULTS,
        displayedItems: action.roots,
        inputNavigationString: action.navString,
        inputNavigationStringForMacOS: action.navStringForMacOS,
        inputSearchString: action.inputSearchString || initialState.inputSearchString,
        openedFolders: initialState.openedFolders,
        searching: false,
        tempDirectories: initialState.tempDirectories,
        usedFilters: action.validUrlFilters,
      };
    }

    case STORAGE_MNG_TOOLBAR_IS_REFRESHING_INPUT_NAV: {
      return {
        ...state,
        refreshingNavInput: action.loading
      };
    }

    case STORAGE_MNG_TOOLBAR_CHANGE_INPUT_NAV: {
      return {
        ...state,
        inputNavigationString: action.value || initialState.inputNavigationString,
        inputNavigationStringForMacOS: action.value || initialState.inputNavigationStringForMacOS
      };
    }

    case STORAGE_MNG_SEARCH_CHANGE_INPUT: {
      return {
        ...state,
        inputSearchString: action.value || initialState.inputSearchString
      };
    }

    case STORAGE_MNG_SHOW_ADV_SEARCH_MODAL: {
      return {
        ...state,
        isVisibleAdvSearchModal: action.visible
      };
    }

    case STORAGE_MNG_ADD_TO_NAVIGATION_HISTORY: {
      return {
        ...state,
        navigationHistory: [...state.navigationHistory, action.item]
      };
    }

    case STORAGE_MNG_CHANGE_NAVIGATION_HISTORY: {
      return {
        ...state,
        navigationHistory: action.navigationHistory
      };
    }

    case STORAGE_MNG_ADD_TO_NAVIGATION_HISTORY_FORWARD: {
      return {
        ...state,
        navigationHistoryForward: [...state.navigationHistoryForward, action.item]
      };
    }

    case STORAGE_MNG_CHANGE_NAVIGATION_HISTORY_FORWARD: {
      return {
        ...state,
        navigationHistoryForward: action.navigationHistoryForward
      };
    }

    case STORAGE_MNG_RESET_NAVIGATION_HISTORY_FORWARD: {
      return {
        ...state,
        navigationHistoryForward: initialState.navigationHistoryForward
      };
    }

    case STORAGE_MNG_SET_AVAILABLE_ACTIONS_BY_SOURCES: {
      return {
        ...state,
        availableActionsBySources: {...action.data}
      };
    }

    case STORAGE_MNG_CHANGE_CHECKED_ITEMS: {
      return {
        ...state,
        availableActionsBySources: initialState.availableActionsBySources,
        checkedItems: [...action.items]
      };
    }

    case STORAGE_MNG_CHANGE_OPENED_FOLDERS: {
      return {
        ...state,
        openedFolders: {...action.folders}
      };
    }

    case STORAGE_MNG_LINK_IN_BURBANK_SHOW_MODAL: {
      return {
        ...state,
        linkInBurbankModalItem: action.item || initialState.linkInBurbankModalItem
      };
    }

    case STORAGE_MNG_LINK_TO_MEMFIS_WO_IS_LOADING: {
      return {
        ...state,
        //linkInBurbankModalItem: initialState.linkInBurbankModalItem,
        availableManualMemfisWOsIsLoading: action.loading
      };
    }

    case STORAGE_MNG_LINK_TO_MEMFIS_WO_SHOW_MODAL: {
      const {isShow, isBackToInBurbankStep} = action;
      return {
        ...state,
        availableManualMemfisWOs: action.data || initialState.availableManualMemfisWOs,
        availableManualMemfisWOsIsLoading: false,
        clientNameIsLoading: false,
        finalDestinationData: initialState.finalDestinationData,
        finalDestinationDataReserved: initialState.finalDestinationDataReserved,
        useFinalDestinationDataReserved: initialState.useFinalDestinationDataReserved,
        finalDestinationIsLoading: false,
        isSingleLinking: action.singleLinking || initialState.isSingleLinking,
        linkInBurbankModalItem: (isShow || isBackToInBurbankStep) ? state.linkInBurbankModalItem : initialState.linkInBurbankModalItem,
      };
    }

    case STORAGE_MNG_LINK_TO_WO_LOAD_CLIENTS_BY_FNAME: {
      const {client} = action;
      return {
        ...state,
        clientByFNameForWO: client
      };
    }

    case STORAGE_MNG_LINK_TO_WO_NEW_WO_BY_FNAME: {
      const {wo} = action;
      return {
        ...state,
        newWoByFName: wo
      };
    }

    case STORAGE_MNG_LINK_TO_MEMFIS_WO_IS_SAVING: {
      return {
        ...state,
        linkToMemfisWOIsSaving: action.loading
      };
    }

    case STORAGE_MNG_LINK_TO_MEMFIS_WO_IS_SAVED: {
      return produce(state, (draft) => {
        draft.linkToMemfisWOIsSaving = false;
        draft.checkedItems[0].memfisWoID = action.memfisWoID;
        draft.checkedItems[0].needToIngest = action.needToIngest;
        const {fsRootID, contentID} = draft.checkedItems[0];
        const item = draft.displayedItems.find(i => i.fsRootID === fsRootID && i.contentID === contentID);
        if (item) {
          item.memfisWoID = action.memfisWoID;
          item.needToIngest = action.needToIngest;
        }
      });
    }

    case STORAGE_MNG_FINAL_DESTINATION_IS_LOADING: {
      const {loading} = action;
      return {
        ...state,
        finalDestinationIsLoading: loading,
        finalDestinationDataReserved: !loading && state.finalDestinationData ? state.finalDestinationData : initialState.finalDestinationDataReserved,
        finalDestinationData: loading ? state.finalDestinationData : initialState.finalDestinationData
      };
    }

    case STORAGE_MNG_FINAL_DESTINATION: {
      return {
        ...state,
        finalDestinationIsLoading: false,
        finalDestinationData: action.data || initialState.finalDestinationData,
        finalDestinationDataReserved: initialState.finalDestinationDataReserved,
        useFinalDestinationDataReserved: initialState.useFinalDestinationDataReserved,
      };
    }

    case STORAGE_MNG_FINAL_DESTINATION_RESERVED: {
      const folders = state.fsRoots.filter(i => i.fsRootID === action.data.preferredRootId).map(i => ({...i}));
      return {
        ...state,
        foldersInModal: [...folders],
        finalDestinationData: state.finalDestinationDataReserved ? state.finalDestinationDataReserved : initialState.finalDestinationData,
        finalDestinationDataReserved: initialState.finalDestinationDataReserved,
        useFinalDestinationDataReserved: state.finalDestinationDataReserved ? true : false
      }
    }

    case USE_STORAGE_MNG_FINAL_DESTINATION_RESERVED: {
      return {
        ...state,
        useFinalDestinationDataReserved: false
      }
    }

    case STORAGE_MNG_INGEST_SHOW_MODAL: {
      return {
        ...state,
        ingestOperationTypesIsLoading: false,
        ingestOperationTypes: action.operationTypes || initialState.ingestOperationTypes,
        ingestWorkOrder: action.workOrder || initialState.ingestWorkOrder,
        ingestFromLinkToWo: action.fromLinkToWo || initialState.ingestFromLinkToWo,
      };
    }

    case STORAGE_MNG_INGEST_OPERATION_TYPES_IS_LOADING: {
      return {
        ...state,
        ingestOperationTypesIsLoading: action.loading
      };
    }

    case STORAGE_MNG_CHECKSUM_GEN: {
      return {
        ...state,
        md5ChecksumIsGenerating: action.loading,
        sha1ChecksumIsGenerating: action.loading
      };
    }

    case STORAGE_MNG_CHECKSUM_SHOW_MODAL: {
      return {
        ...state,
        md5ChecksumOpening: true,
        sha1ChecksumOpening: true,
        checksumOnlyType: false,
        checksumIsShowModal: action.isShow
      };
    }

    case STORAGE_MNG_PSE_SHOW_MODAL: {
      return {
        ...state,
        pseType: null,
        pseFile: null,
        pseCompanion: null,
        pseIsShowModal: action.isShow
      };
    }

    case STORAGE_MNG_METAFIER_SHOW_MODAL: {
      return {
        ...state,
        metafierIsShowModal: action.isShow
      };
    }

    //case STORAGE_MNG_EDGE_PASS_SHOW_MODAL: {
    //  return {
    //    ...state,
    //    edgePassIsShowModal: action.isShow
    //  };
    //}

    //case STORAGE_MNG_SCREEN_TO_QOD_SHOW_MODAL: {
    //  return {
    //    ...state,
    //    screenToQodIsShowModal: action.isShow
    //  };
    //}

    case STORAGE_MNG_FRAME_RATE_SHOW_MODAL: {
      return {
        ...state,
        frameRateIsShowModal: action.isShow
      };
    }

    case STORAGE_MNG_SET_FRAME_RATE: {
      let {value} = action;

      return produce(state, (draft) => {
        draft.LKFSModalState.newFrameRate = value;
        draft.LKFSModalState.isUserFrameRate = true;
        draft.LKFSModalState.isLKFSSubmit = true;
      });
    }

    case STORAGE_MNG_SET_ERROR_NUMBER_FRAME_RATE: {
      let {value} = action;

      return produce(state, (draft) => {
        draft.LKFSModalState.isErrorNumberFrameRate = value;
      });
    }

    case STORAGE_MNG_ALERT_SHOW_MODAL: {
      return {
        ...state,
        alertIsShowModal: action.isShow
      };
    }

    case STORAGE_MNG_ALERT_MODAL_DATA: {
      return {
        ...state,
        alertModalType: action.allert_type,
        alertModalMessage: action.allert_message
      };
    }

    case STORAGE_MNG_CHECKSUM_MD5_SHOW_MODAL: {
      return {
        ...state,
        md5ChecksumOpening: true,
        sha1ChecksumOpening: false,
        checksumOnlyType: true,
        checksumIsShowModal: action.isShow
      };
    }

    case STORAGE_MNG_CHECKSUM_SHA1_SHOW_MODAL: {
      return {
        ...state,
        md5ChecksumOpening: false,
        sha1ChecksumOpening: true,
        checksumOnlyType: true,
        checksumIsShowModal: action.isShow
      };
    }

    case STORAGE_MNG_INSTANT_SCAN_START: {
      return {
        ...state,
        instantScanning: true,
        instantScanObject: action.instantScanObject || initialState.instantScanObject,
        instantScanActionResult: state.instantScanActionResult ? [] : initialState.instantScanActionResult
      };
    }

    case STORAGE_MNG_INSTANT_SCAN_STOP: {
      const {data} = action;
      return {
        ...state,
        instantScanning: initialState.instantScanning,
        instantScanObject: data ? state.instantScanObject : initialState.instantScanObject,
        instantScanActionResult: data || initialState.instantScanActionResult
      };
    }

    case STORAGE_MNG_INSTANT_SCAN_CLOSE: {
      return {
        ...state,
        instantScanObject: initialState.instantScanObject,
        instantScanActionResult: initialState.instantScanActionResult
      };
    }

    case STORAGE_MNG_READ_FILE_START: {
      return {
        ...state,
        isReadingFile: true
      };
    }

    case STORAGE_MNG_READ_FILE_STOP: {
      return {
        ...state,
        isReadingFile: initialState.isReadingFile
      };
    }

    case STORAGE_MNG_ACTIONS_IS_LOADING: {
      return {
        ...state,
        actionsIsLoading: action.loading
      };
    }

    case STORAGE_MNG_ACTIONS_CHILDREN: {
      return {
        ...state,
        actionsIsLoading: false,
        actionsChildren: {...state.actionsChildren, [action.actionId]: action.children || []}
      };
    }

    case STORAGE_MNG_ACTIONS_PARENT: {
      const actionId = action.action.actionID;
      return {
        ...state,
        actionsIsLoading: false,
        actionsParent: {...state.actionsParent, [actionId]: action.action},
        actionsChildren: {...state.actionsChildren, [actionId]: action.children || []}
      };
    }

    case STORAGE_MNG_ACTIONS_SHOW: {
      return {
        ...state,
        actionsTotal: (action.data ? (action.total || action.data.length) : initialState.actionsTotal),
        actions: action.data || initialState.actions,
        actionsFilters: action.actionsFilters || initialState.actionsFilters,
        actionsRequestorsMap: action.actionsRequestorsMap || initialState.actionsRequestorsMap,
        actionsIsLoading: false,
        sourceOfActionsRequest: action.source || initialState.sourceOfActionsRequest,
        usedActionsFilters: action.usedFilters || initialState.usedActionsFilters,
      };
    }

    case STORAGE_MNG_ACTIONS_CLOSE: {
      return {
        ...state,
        usedActionsFilters: initialState.usedActionsFilters,
        actionsTotal: initialState.actionsTotal,
        actions: initialState.actions
      };
    }

    case STORAGE_MNG_ACTIONS_CONTENTS_IS_LOADING: {
      return {
        ...state,
        actionContentsIsLoading: action.loading
      };
    }

    case STORAGE_MNG_ACTIONS_CONTENTS: {
      return {
        ...state,
        actionContentsIsLoading: false,
        appliedNavigationString: action.navString,
        appliedNavigationStringForMacOS: action.navStringForMacOS,
        appliedSearchString: initialState.appliedSearchString,
        availableActionsBySources: initialState.availableActionsBySources,
        checkedItems: initialState.checkedItems,
        currentAction: action.action,
        currentFolder: initialState.currentFolder,
        displayCategory: DISPLAY_CATEGORY_ACTION_CONTENTS,
        displayedItems: action.contents || initialState.displayedItems,
        inputNavigationString: action.navString,
        inputNavigationStringForMacOS: action.navStringForMacOS,
        inputSearchString: initialState.inputSearchString,
        tempDirectories: initialState.tempDirectories,
        usedFilters: initialState.usedFilters,
      };
    }

    case STORAGE_MNG_ACTIONS_REQUESTORS_SHOW: {
      return {
        ...state,
        actionsRequestorsMap: action.actionsRequestorsMap || initialState.actionsRequestorsMap
      };
    }

    case STORAGE_MNG_ACTIONS_SUBSCRIBERS_SHOW: {
      return {
        ...state,
        actionsSubscriberMap: action.actionsSubscriberMap
      };
    }

    case STORAGE_MNG_LOAD_ACTION_PERMISSIONS: {
      return {
        ...state,
        actionPermissions: action.res
      }
    }

    case STORAGE_MNG_ACTIONS_USER_PERMISSIONS_IS_LOADING: {
      return {
        ...state,
        actionsUserPermissionsIsLoading: action.loading
      };
    }

    case STORAGE_MNG_ACTIONS_USER_PERMISSIONS: {
      return {
        ...state,
        actionsUserPermissionsIsLoading: false,
        actionsUserPermissions: action.permissions || initialState.actionsUserPermissions,
        actionsUserPermissionsWithRoots: action.permissionsWithRoots || initialState.actionsUserPermissionsWithRoots
      };
    }

    case STORAGE_MNG_ACTIONS_USER_PERMISSIONS_WITHOUT_ROOTS: {
      return {
        ...state,
        actionsUserPermissionsIsLoading: false,
        actionsUserPermissions: action.permissions || initialState.actionsUserPermissions
      };
    }

    case STORAGE_MNG_MY_ACTIONS_SHOW: {
      return {
        ...state,
        myActions: action.data || initialState.myActions,
      };
    }

    case STORAGE_MNG_MY_ACTIONS_CLOSE: {
      return {
        ...state,
        myActions: initialState.myActions
      };
    }

    case STORAGE_MNG_MY_ACTIONS_CONTENTS_IS_LOADING: {
      return {
        ...state,
        actionContentsIsLoading: action.loading
      };
    }

    case STORAGE_MNG_MY_ACTIONS_ACTIVE_CONTENTS: {
      return {
        ...state,
        hasActiveMyActions: action.hasActiveMyActions,
        allActiveUsers: action.allActiveUsers
      };
    }

    case STORAGE_MNG_NAV_INTO_IS_LOADING: {
      return {
        ...state,
        navigationIntoIsLoading: action.loading
      };
    }

    case STORAGE_MNG_NAV_INTO_FETCH_FOLDER: {
      const {isCheckedFolder, folder, children, allParents} = action;
      return produce(state, (draft) => {
        const checkedItems = addChildrenIntoFolder(draft.checkedItems, folder, children, allParents);
        let checkedChildren;
        if (isCheckedFolder) {
          checkedChildren = [...children || []];
        }
        draft.navigationIntoIsLoading = false;
        draft.displayedItems = addChildrenIntoFolder(draft.displayedItems, folder, children, allParents);
        draft.checkedItems = [...checkedItems, ...checkedChildren || []];
        draft.availableActionsBySources = {};
      });
    }

    case STORAGE_MNG_PREDICTIVE_STATES_IS_LOADING: {
      return {
        ...state,
        predictiveStatesIsLoading: action.loading
      };
    }

    case STORAGE_MNG_PREDICTIVE_STATES: {
      return {
        ...state,
        predictiveStatesIsLoading: false,
        predictiveStates: action.data || initialState.predictiveStates
      };
    }

    case STORAGE_MNG_LOCK_AND_SERVICES_STATUSES_IS_LOADING: {
      return {
        ...state,
        lockAndServicesStatusesIsLoading: action.loading
      };
    }

    case STORAGE_MNG_LOCK_AND_SERVICES_STATUSES: {
      return {
        ...state,
        unknownFsRoots: action.newUnknownFsRoots || initialState.unknownFsRoots,
        lockAndServicesStatusesIsLoading: false,
        lockAndServicesStatuses: action.data || initialState.lockAndServicesStatuses
      };
    }

    case STORAGE_MNG_ACTIVE_FS_ROOTS_IS_LOADING: {
      return {
        ...state,
        availableFsRootsIsLoading: action.loading
      };
    }

    case STORAGE_MNG_ACTIVE_FS_ROOTS: {
      const {data} = action;
      let unknownFsRoots = initialState.unknownFsRoots;
      if (state.unknownFsRoots && state.unknownFsRoots.length && data) {
        unknownFsRoots = [...state.unknownFsRoots].filter(id => !data.some(root => id === root.value));
      }
      return {
        ...state,
        unknownFsRoots,
        availableFsRootsIsLoading: false,
        availableFsRoots: data || initialState.availableFsRoots,
        fsRoots: action.roots || initialState.fsRoots
      };
    }

    case STORAGE_MNG_SET_CURRENT_VIEW: {
      return {
        ...state,
        currentView: action.view || initialState.currentView
      };
    }

    case STORAGE_MNG_SET_HIDE_TEMP_FILES: {
      return {
        ...state,
        hideTempFiles: action.hideTempFiles || initialState.hideTempFiles
      };
    }

    case STORAGE_MNG_INITIAL_CONFIRMATION_DELETION: {
      return {
        ...state,
        appliedNavigationString: initialState.appliedNavigationString,
        appliedNavigationStringForMacOS: initialState.appliedNavigationStringForMacOS,
        appliedSearchString: initialState.appliedSearchString,
        availableActionsBySources: initialState.availableActionsBySources,
        checkedItems: [...action.items],
        currentAction: initialState.currentAction,
        currentFolder: initialState.currentFolder,
        displayCategory: DISPLAY_CATEGORY_INIT_CONFIRM_DELETION,
        displayedItems: [...action.items],
        inputNavigationString: initialState.inputNavigationString,
        inputNavigationStringForMacOS: initialState.inputNavigationStringForMacOS,
        inputSearchString: initialState.inputSearchString,
        openedFolders: initialState.openedFolders,
        tempDirectories: initialState.tempDirectories,
        usedFilters: initialState.usedFilters,
      };
    }

    case STORAGE_MNG_MODAL_IS_LOADING: {
      return {
        ...state,
        isLoadingModal: action.loading
      };
    }

    case STORAGE_MNG_FOLDER_CHILDREN_RECEIVE: {
      const {folder, children, allParents} = action;
      return produce(state, (draft) => {
        draft.isLoadingModal = false;
        draft.foldersInModal = addChildrenIntoFolder(draft.foldersInModal, folder, children, allParents);
      })
    }

    case STORAGE_MNG_CHANGE_OPENED_FOLDERS_IN_MODAL: {
      return {
        ...state,
        openedFoldersInModal: {...action.folders}
      };
    }

    case STORAGE_MNG_SET_FOLDERS_IN_MODAL: {
      return {
        ...state,
        foldersInModal: [...action.folders]
      };
    }

    case STORAGE_MNG_SET_MODAL_STEP: {
      return {
        ...state,
        modalState: action.state
      }
    }

    case STORAGE_MNG_MOVE_SHOW_MODAL: {
      const {isShow, allowedActions} = action;
      const {fsRoots} = state;
      return {
        ...state,
        isShowMoveModal: isShow,
        allowedMoveActions: allowedActions || initialState.allowedMoveActions,
        isLoadingModal: initialState.isLoadingModal,
        foldersInModal: isShow ? fsRoots.map((r) => ({...r})) : initialState.foldersInModal,
        openedFoldersInModal: initialState.openedFoldersInModal,
      };
    }

    case STORAGE_MNG_SCHEDULED_PAUSE_SHOW_MODAL: {
      const {isShow, selectedAction} = action;
      return {
        ...state,
        isShowScheduledPauseModal: isShow,
        scheduledPauseSelectedAction: selectedAction
      };
    }

    case STORAGE_MNG_RENAME_SHOW_MODAL: {
      return {
        ...state,
        isShowRenameModal: action.isShow,
        renameModalData: action.renameModalData || initialState.renameModalData,
        isLoadingModal: initialState.isLoadingModal
      };
    }

    case STORAGE_MNG_NEW_DIRECTORY_SHOW_MODAL: {
      return {
        ...state,
        isShowNewFolderModal: action.isShow,
        isLoadingModal: initialState.isLoadingModal,
      };
    }

    case STORAGE_MNG_SET_LOADING: {
      return {
        ...state,
        encDetailExists: action.encDetailExists,
        loaders: {
          ...state.loaders,
          [action.name]: action.loading
        }
      };
    }

    case STORAGE_MNG_FILE_SETTINGS_ALLOW_READING: {
      const fileSettings = action.data || initialState.fileSettingsAllowReading;
      const fileExtensions = Object.keys(fileSettings).map(ext => ext.replace(/^[.]+/, ''));
      let regExpAllowReadingFile;
      if (fileExtensions.length > 0) {
        regExpAllowReadingFile = new RegExp('^.+[.](' + fileExtensions.join('|') + ')$', 'i');
      }
      const zipFileSettings = fileSettings['.zip'];
      const zipFileSetting = Array.isArray(zipFileSettings) ?
        zipFileSettings.find(s => s.propertyName === 'max_total_size_to_zip' && !!s.PropertyValue) :
        initialState.zipFileSetting;
      return {
        ...state,
        loaders: {
          ...state.loaders,
          fileSettingsAllowReading: false
        },
        fileSettingsAllowReading: fileSettings,
        regExpAllowReadingFile: regExpAllowReadingFile || initialState.regExpAllowReadingFile,
        zipFileSetting
      };
    }

    case STORAGE_MNG_SHOW_APLUS_SETTINGS: {
      return {
        ...state,
        loaders: {
          ...state.loaders,
          aplusSettingsLoading: false
        },
        aplusSettings: action.data || initialState.aplusSettings
      };
    }

    case STORAGE_MNG_SHOW_MANUALLY_RECONCILE_MODAL: {
      return {
        ...state,
        manuallyReconcileData: action.data || initialState.manuallyReconcileData
      };
    }

    case STORAGE_MNG_SHOW_SELECT_FOLDER_MODAL: {
      return {
        ...state,
        isShowSelectFolderModal: action.isShow || initialState.isShowSelectFolderModal
      };
    }

    case STORAGE_MNG_SHOW_AVAILABLE_DRIVES_MODAL: {
      return {
        ...state,
        availableDrivesModalData: action.data || initialState.availableDrivesModalData
      };
    }

    case STORAGE_MNG_CONNECT_DRIVE: {
      const {drive} = action;
      return produce(state, (draft) => {
        const {FsRootLockStatuses} = draft.lockAndServicesStatuses;
        if (FsRootLockStatuses) {
          const lockStatusOfRoot = FsRootLockStatuses.find(r => r.FSRootID === drive.FSRootID);
          if (lockStatusOfRoot) {
            lockStatusOfRoot.DriveLabel = drive.DriveLabel;
            lockStatusOfRoot.DriveLabelAlias = drive.DriveLabelAlias;
          }
        }
        draft.availableDrivesModalData = initialState.availableDrivesModalData;
        draft.loaders.connectDrive = false;
      });
    }

    case STORAGE_MNG_DISCONNECT_DRIVE: {
      const {drive} = action;
      return produce(state, (draft) => {
        const {FsRootLockStatuses} = draft.lockAndServicesStatuses;
        if (FsRootLockStatuses) {
          const lockStatusOfRoot = FsRootLockStatuses.find(r => r.FSRootID === drive.FSRootID);
          if (lockStatusOfRoot) {
            delete lockStatusOfRoot.DriveLabel;
            delete lockStatusOfRoot.DriveLabelAlias;
          }
        }
        draft.loaders.disconnectDrive = false;
      });
    }

    case STORAGE_MNG_SHOW_ENCRYPT_MODAL: {
      const {checkedItems} = state;
      return {
        ...state,
        isShowEncryptModal: action.isShow || initialState.isShowEncryptModal,
        isLoadingModal: initialState.isLoadingModal,
        foldersInModal: action.isShow ?
          state.fsRoots.filter(i => i.fsRootID === checkedItems[0].fsRootID).map(i => ({...i})) :
          initialState.foldersInModal,
        openedFoldersInModal: initialState.openedFoldersInModal
      };
    }

    case STORAGE_MNG_SINGLE_ENCRYPT_OR_DECRYPT: {
      return produce(state, (draft) => {
        const {checkedItems, displayedItems} = draft;
        checkedItems[0].isEncrypted = action.isEncrypted;
        const {fsRootID, contentID} = checkedItems[0];
        const item = displayedItems.find(i => i.fsRootID === fsRootID && i.contentID === contentID);
        if (item) {
          item.isEncrypted = action.isEncrypted;
        }
        draft.loaders.singleEncryptOrDecrypt = false;
        draft.loaders.getEncryptOrDecryptPassword = false;
        draft.isShowEncryptModal = initialState.isShowEncryptModal;
        draft.isLoadingModal = initialState.isLoadingModal;
        draft.foldersInModal = initialState.foldersInModal;
        draft.openedFoldersInModal = initialState.openedFoldersInModal;
      });
    }

    case STORAGE_MNG_TEMP_DIRS_ADDED: {
      const {newDir} = action;

      return produce(state, (draft) => {
        const {tempDirectories} = draft;
        const {fsRootID, parentID, key} = newDir;
        const dirKey = `r${fsRootID}-p${parentID}-${key}`;
        delete tempDirectories.deleted[dirKey];
        tempDirectories.added[dirKey] = newDir;
      });
    }

    case STORAGE_MNG_TEMP_DIRS_DELETED: {
      return produce(state, (draft) => {
        const {tempDirectories} = draft;
        const {fsRootID, items} = action;
        items.forEach(item => {
          if (item.isDirectory) {
            const dirKey = `r${fsRootID}-p${item.parentID}-${item.key}`;
            delete tempDirectories.added[dirKey];
            tempDirectories.deleted[dirKey] = {key: item.key};
          }
        });
      });
    }

    case STORAGE_MNG_REMOVE_FROM_TEMP_DIRS: {
      const {tempDirectoriesToRemoved} = action;

      return produce(state, (draft) => {
        const {tempDirectories} = draft;
        tempDirectoriesToRemoved.added.forEach(dirKey => {
          delete tempDirectories.added[dirKey];
        });
        tempDirectoriesToRemoved.deleted.forEach(dirKey => {
          delete tempDirectories.deleted[dirKey];
        });
      });
    }

    case STORAGE_MNG_CLIENT_NAME_LOADING: {
      return {
        ...state,
        clientNameIsLoading: action.loading
      };
    }

    case STORAGE_MNG_CLIENT_NAME_SET: {
      const {woMemfisId, clientName} = action;
      return produce(state, (draft) => {
        const {availableManualMemfisWOs} = draft;
        if (availableManualMemfisWOs) {
          const wo = availableManualMemfisWOs.find(i => i.woMemfisId === woMemfisId);
          if (wo && !wo.clientName) {
            wo.clientName = clientName;
          }
        }
        draft.clientNameIsLoading = false;
      });
    }

    case STORAGE_MNG_CLIENT_LIST_SET: {
      const {woMemfisId, clientList} = action;
      return produce(state, (draft) => {
        const {availableManualMemfisWOs} = draft;
        if (availableManualMemfisWOs) {
          const wo = availableManualMemfisWOs.find(i => i.woMemfisId === woMemfisId);
          if (wo) {
            wo.clientList = clientList;
          }
        }
        draft.clientNameIsLoading = false;
      });
    }

    case STORAGE_MNG_REFRESH_SEL_CONTENT_IN_CUR_FOLDER: {
      const {content, fsRootID, contentID} = action;
      return produce(state, (draft) => {
        const {displayedItems, checkedItems} = draft;
        const displayedItemIndex = displayedItems.findIndex(i => i.fsRootID === fsRootID && i.contentID === contentID);
        if (displayedItemIndex !== -1) {
          if (content) {
            displayedItems[displayedItemIndex] = {...displayedItems[displayedItemIndex], ...content};
          } else {
            displayedItems.splice(displayedItemIndex, 1);
          }
        }
        const checkedItemIndex = checkedItems.findIndex(i => i.fsRootID === fsRootID && i.contentID === contentID);
        if (checkedItemIndex !== -1) {
          if (content) {
            checkedItems[checkedItemIndex] = {...checkedItems[checkedItemIndex], ...content};
          } else {
            checkedItems.splice(checkedItemIndex, 1);
          }
        }
        draft.availableActionsBySources = {};
        draft.loaders.refreshSelectedContent = false;
      });
    }

    case STORAGE_MNG_REFRESH_SEL_ROOT: {
      return produce(state, (draft) => {
        const {fsRootID} = draft.checkedItems[0];
        const {fsRoots, availableFsRoots} = action;
        const fsRoot = fsRoots.find(r => r.fsRootID === fsRootID);
        draft.checkedItems = fsRoot ? [fsRoot] : [];
        draft.availableFsRoots = availableFsRoots;
        draft.loaders.refreshSelectedContent = false;
        draft.fsRoots = fsRoots;
        draft.displayedItems = fsRoots;
      });
    }

    case STORAGE_MNG_COPY_SHOW_MODAL: {
      const {data, isShow, allowedActions} = action;
      return {
        ...state,
        loaders: {
          ...state.loaders,
          getCopyData: false,
          copyFsItem: false
        },
        copyModalData: data || initialState.copyModalData,
        foldersInModal: data ?
          (Array.isArray(data.copyToFsRootIDs) && data.copyToFsRootIDs.length ? state.fsRoots.filter(i => data.copyToFsRootIDs.includes(i.fsRootID)).map(i => ({...i})) : []) :
            initialState.foldersInModal,
        openedFoldersInModal: initialState.openedFoldersInModal,
        isShowCopyModal: isShow,
        allowedCopyActions: allowedActions || initialState.allowedCopyActions
     };
    }

    case STORAGE_MNG_COPY_TO_QOD_SHOW_MODAL: {
      return {
        ...state,
        copyModalData: action.data || initialState.copyModalData,
        isShowCopyToQodModal: action.isShow || initialState.isShowCopyToQodModal
      };
    }

    case STORAGE_MNG_RESET_COPY_MODAL_DATA: {
      return {
        ...state,
        copyModalData: initialState.copyModalData
      };
    }

    case STORAGE_MNG_RESET_COPY_TO_QOD_MODAL_DATA: {
      return {
        ...state,
        copyToQodModalState: initialState.copyToQodModalState
      };
    }

    case STORAGE_MNG_SET_QOD_INFO: {
      return produce(state, (draft) => {
        const {data: {QODProxyInfo, PseAnalyseInfo, QODProxyInfoList, EnableLinkToWO, EnableAddToWO, DisneySelection, DisneySelectionWO, AddToWoFileRootFolderWO, EnableXxHash, XxHashOn, XxHashIssue}} = action;
        draft.copyToQodModalState.copyToQodInfo = QODProxyInfo;
        draft.copyToQodModalState.copyToQodInfoList = QODProxyInfoList;
        draft.copyToQodModalState.pseAnalyseInfo = PseAnalyseInfo;
        draft.enableLinkToWO = EnableLinkToWO;
        draft.enableAddToWO = EnableAddToWO;
        draft.EnableXxHash = EnableXxHash;
        draft.XxHashOn = XxHashOn;
        draft.XxHashIssue = XxHashIssue;
        draft.disneySelection = DisneySelection;
        draft.disneySelectionWO = DisneySelectionWO;
        draft.addToWoFileRootFolderWO = AddToWoFileRootFolderWO;
      });
    }

    case STORAGE_MNG_ADD_FILE_TO_WO_SET_DISNEY_INFO: {
      const modalStateName = getModalStateName(action.type);
      const {
        show_file_selection,
        ingest_files,
        ingest_copy_folder
      } = action.data;

      return produce(state, (draft) => {
        draft[modalStateName].showFileSelection = show_file_selection;
        draft[modalStateName].ingestFiles = ingest_files;
        draft[modalStateName].ingestCopyFolder = ingest_copy_folder;
      });
    }

    case STORAGE_MNG_ADD_FILE_TO_WO_SET_EMPTY_DISNEY_INFO: {
      const modalStateName = getModalStateName(action.type);

      return produce(state, (draft) => {
        draft[modalStateName].showFileSelection = false;
        draft[modalStateName].ingestFiles = null;
        draft[modalStateName].ingestCopyFolder = null;
      });
    }

    case STORAGE_MNG_SET_ENABLE_ACTION_TO_WO: {
      return {
        ...state,
        enableLinkToWO: true,
        enableAddToWO: true
      };
    }

    case STORAGE_MNG_SET_QOD_AVAILABLE_WOMEMFISES: {
      return produce(state, (draft) => {
        draft.copyToQodModalState.availableWOmemfises = action.availableWOmemfises;
      });
    }

    case STORAGE_MNG_SET_QOD_PROXIES: {
      const {ownProxyName} = action;
      return produce(state, (draft) => {
        if (ownProxyName) {
          draft.copyToQodModalState.ownProxyName = ownProxyName;
        }
      });
    }

    case STORAGE_MNG_CONTENT_PERMISSIONS_SHOW_MODAL: {
      return {
        ...state,
        isShowContentPermissionsModal: action.isShow || initialState.isShowContentPermissionsModal
      };
    }

    case STORAGE_MNG_SCRIPT_RUNNER_ACTION_STATUS_BIT: {
      const {actionData: {runnerKey, FsRootID, isService, isOffice, OfficeFlag}, statusBit} = action;

      return produce(state, (draft) => {
        const {lockAndServicesStatuses} = draft;
        const {FsRootLockStatuses, OfficeServiceStatuses, OtherRunners} = lockAndServicesStatuses;
        if (!isOffice) {
          if (!isService && FsRootLockStatuses) {
            const lockStatusOfRoot = FsRootLockStatuses.find(r => r.FSRootID === FsRootID);
            if (lockStatusOfRoot) {
              lockStatusOfRoot[runnerKey] = {...lockStatusOfRoot[runnerKey], StatusBits: statusBit};
            }
          } else if (isService && OtherRunners) {
            (OtherRunners[runnerKey] || {}).StatusBits = statusBit;
          }
        } else if (OfficeServiceStatuses) {
          const office = OfficeServiceStatuses.find(o => o.OfficeFlag === OfficeFlag);
          if (office) {
            office[runnerKey] = {...office[runnerKey], StatusBits: statusBit};
          }
        }
        draft.loaders.executeScriptRunnerAction = false;
      });
    }

    case STORAGE_MNG_FS_RUNNER_ACTION_STATUS_BIT: {
      const {fsRootID, statusBit} = action;
      return produce(state, (draft) => {
        const {lockAndServicesStatuses} = draft;
        const {FileSystemStatuses} = lockAndServicesStatuses;
        if (FileSystemStatuses && FileSystemStatuses[fsRootID]) {
          FileSystemStatuses[fsRootID].BitStatus = statusBit;
        }
      });
    }

    case STORAGE_MNG_MACHINE_RUNNER_ACTION_STATUS_BIT: {
      const {machineName, statusBit} = action;

      return produce(state, (draft) => {
        const {lockAndServicesStatuses} = draft;
        const {MachineStatuses, OtherRunners} = lockAndServicesStatuses;
        if (MachineStatuses && MachineStatuses[machineName]) {
          MachineStatuses[machineName].BitStatus = statusBit;
        }
        if (OtherRunners && OtherRunners[machineName]) {
          OtherRunners[machineName].StatusBits = statusBit;
        }
        draft.loaders.executeMachineRunnerAction = false;
      });
    }

    case STORAGE_MNG_SET_CONTENT_PERMISSIONS_SUCCESS: {
      const {permissionsSymbolic} = action;
      return produce(state, (draft) => {
        const {checkedItems, displayedItems} = draft;
        checkedItems[0].permissions = permissionsSymbolic;
        const {fsRootID, contentID} = checkedItems[0];
        const item = displayedItems.find(i => i.fsRootID === fsRootID && i.contentID === contentID);
        if (item) {
          item.permissions = permissionsSymbolic;
        }
        draft.loaders.setContentPermissions = false;
      });
    }

    case STORAGE_MNG_OFFICE_ACTION_STATUS_BIT: {
      const {officeFlag, statusBits} = action;
      return produce(state, (draft) => {
        draft.lockAndServicesStatuses.OfficeServiceStatuses.forEach((statusObj) => {
          if (statusObj.OfficeFlag === officeFlag) {
            statusObj.StatusBits = statusBits;
          }
        });
        draft.loaders.executeOfficeAction = false;
      });
    }

    /*#region Settings */
    case STORAGE_MNG_SWITCH_SETTINGS_MODAL: {
      const {isOpen} = action;
      return {
        ...state,
        isSettingsModalOpen: isOpen,
      };
    }

    case STORAGE_MNG_LOAD_SETTINGS: {
      const {settings, extensionSettings} = action;
      if (settings) {
        return produce(state, (draft) => {
          draft.settings = settings;
          draft.isLoadingModal = false;
        });
      } else if (extensionSettings) {
        return produce(state, (draft) => {
          if (extensionSettings) {
            draft.extensionSettings = extensionSettings;
          }
          draft.isLoadingModal = false;
        });
      } else {
        return {...state}
      }
    }

    case STORAGE_MNG_SAVE_SETTINGS: {
      const {editedSettings} = action;
      return produce(state, (draft) => {
        Object.entries(draft.settings).forEach(([categoryCodeName, categoryObj]) => {
          Object.entries(categoryObj.values).forEach(([valueCodeName, valueObj]) => {
            valueObj.value = editedSettings[categoryCodeName].values[valueCodeName].value;
          });
        });
        draft.isLoadingModal = false;
      });
    }
    /*#endregion */

    /*#region Settings */
    case STORAGE_MNG_SWITCH_MY_ACTIONS_MODAL: {
      const {isOpen} = action;
      return {
        ...state,
        isMyActionsModalOpen: isOpen,
      };
    }
    /*#endregion */

    case STORAGE_MNG_ALL_OFFICES_ACTION_STATUS_BIT: {
      return produce(state, (draft) => {
        draft.loaders.executeAllOfficesAction = false;
        draft.lockAndServicesStatuses.DATService.StatusBits = action.statusBits;
      });
    }

    /*#region LKFS */
    case STORAGE_MNG_LKFS_SWITCH_MODAL: {
      const {
        isShow,
      } = action;
      return produce(state, (draft) => {
        if (!isShow) {
          draft.LKFSModalState = JSON.parse(JSON.stringify(initialState.LKFSModalState));
        }
        draft.LKFSModalState.show = isShow;
      });
    }

    /* If one and only one checked file has wo, using this wo. */
    case STORAGE_MNG_LKFS_SET_AUTO_WO_STATUS: {
      const {memfisWoID} = action;
      return produce(state, (draft) => {
        draft.LKFSModalState.isAutoWO = true;
        draft.LKFSModalState.initialWOMemfis = memfisWoID;
        draft.LKFSModalState.currentWOMemfis = memfisWoID;
      });
    }

    case STORAGE_MNG_LKFS_UPDATE_AMB: {
      let {new1, new3, new4} = action;
      const numericAMB = (Number(new4) * 8192) + (Number(new3) * 2) + Number(new1);

      return produce(state, (draft) => {
        draft.LKFSModalState.AMBCurrent = numericAMB;
      });
    }

    case STORAGE_MNG_LKFS_AUTO_UPDATE_AMB: {
      let {numericAMB} = action;
      if (state.LKFSModalState.AMBInitial === state.LKFSModalState.AMBCurrent) {
        return produce(state, (draft) => {
          draft.LKFSModalState.AMBInitial = numericAMB;
          draft.LKFSModalState.AMBCurrent = numericAMB;
        });
      }
      return { ...state };
    }

    case STORAGE_MNG_ADD_FILE_TO_WO_CHANGE_STATUS: {
      const {newScreen, status} = action;
      const modalStateName = getModalStateName(action.type);
      return produce(state, (draft) => {
        if (newScreen) {
          draft[modalStateName][newScreen] = status;
        }
      });
    }

    case STORAGE_MNG_ADD_FILE_TO_WO_GOTO_SCREEN:
    case STORAGE_MNG_LKFS_GOTO_SCREEN: {
      const {newScreen} = action;
      const modalStateName = getModalStateName(action.type);
      return produce(state, (draft) => {
        if (newScreen) {
          draft[modalStateName][newScreen] = true;
          if (newScreen === 'isSelectingDestination') {
            /*if (isActionTypeForAddFileToWO(action.type)) {*/
              draft.foldersInModal = JSON.parse(JSON.stringify(action.fsRoots));
              draft.openedFoldersInModal = {};
            /*} else {
              draft.folderExplorerFolders = JSON.parse(JSON.stringify(state.fsRoots));
            }*/
          }
          if (newScreen === 'isInitialScreen') {
            draft[modalStateName].isSelectingWOMemfis = false;
            draft[modalStateName].isSelectingDestination = false;
            draft[modalStateName].isIngestScreen = false;
            draft[modalStateName].isInnerFileIngestScreen = false;
            draft[modalStateName].isFilesScreen = false;
          }
        } else {
          draft[modalStateName].isSelectingWOMemfis = false;
          draft[modalStateName].isSelectingDestination = false;
          draft[modalStateName].isInitialScreen = false;
          draft[modalStateName].isIngestScreen = false;
          draft[modalStateName].isInnerFileIngestScreen = false;
          draft[modalStateName].isFilesScreen = false;
        }
      });
    }

    case STORAGE_MNG_ADD_FILE_TO_WO_UPDATE_WO_MEMFIS:
    case STORAGE_MNG_LKFS_UPDATE_WO_MEMFIS: {
      const {value} = action;
      const modalStateName = getModalStateName(action.type);
      return produce(state, (draft) => {
        draft[modalStateName].currentWOMemfis = value;
        draft[modalStateName].isSelectingWOMemfis = false;
      });
    }

    case STORAGE_MNG_ADD_FILE_TO_WO_SET_ASSIGN_NEW_WO: {
      const modalStateName = getModalStateName(action.type);
      return produce(state, (draft) => {
        draft[modalStateName].isAssignNewWo = action.isChecked;
        draft[modalStateName].newWOMemfis = '';
      });
    }

    case STORAGE_MNG_ADD_FILE_TO_WO_SET_NEW_WO: {
      const modalStateName = getModalStateName(action.type);
      return produce(state, (draft) => {
        draft[modalStateName].newWOMemfis = action.newWOMemfis;
        draft[modalStateName].isSelectingWOMemfis = false;
      });
    }

    case STORAGE_MNG_ADD_FILE_TO_WO_SET_DELIVERY_RENAME_AVAILABLE: {
      const modalStateName = getModalStateName(action.type);
      return produce(state, (draft) => {
        const {woMemfis, value} = action;
        draft[modalStateName].deliveryRenameAvailable = {woMemfis, value};
      });
    }

    case STORAGE_MNG_LKFS_RESET: {
      const {resetField} = action;
      if (resetField === 'destination') {
        return produce(state, (draft) => {
          draft.LKFSModalState.currentDestination = {
            ...draft.LKFSModalState.initialDestination
          };
        });
      } else if (resetField === 'amb') {
        return produce(state, (draft) => {
          draft.LKFSModalState.AMBCurrent = draft.LKFSModalState.AMBInitial;
        });
      }
      return {...state}
    }

    case STORAGE_MNG_ADD_FILE_TO_WO_CHOOSE_DESTINATION:
    case STORAGE_MNG_LKFS_CHOOSE_DESTINATION: {
      const modalStateName = getModalStateName(action.type);
      const {folder} = action;

      return produce(state, (draft) => {
        draft[modalStateName].isSelectingDestination = false;
        draft[modalStateName].currentDestination = folder;
        draft[modalStateName].currentDestinationShort = {
          key: folder.key.slice(0, 2),
          fsRootID: folder.fsRootID
        };
        draft[modalStateName].isDestinationChanged = true;
        // draft[modalStateName].default_symlink_root_directory_body = null;
        // draft[modalStateName].default_symlink_root_directory_tail = null;
      });
    }

    case STORAGE_MNG_ADD_FILE_TO_WO_CHOOSE_COPY_DESTINATION: {
      const modalStateName = getModalStateName(action.type);

      return produce(state, (draft) => {
        draft[modalStateName].isSelectingDestination = false;
        draft[modalStateName].currentCopyDestination = action.folder;
      });
    }

    case STORAGE_MNG_LKFS_AUTO_CHOOSE_DESTINATION: {
      const modalStateName = getModalStateName(action.type);
      const {folder} = action;
      const {initialDestination, currentDestination} = state[modalStateName];
      if (initialDestination.key === currentDestination.key) {
        return produce(state, (draft) => {
          draft[modalStateName].initialDestination = folder;
          draft[modalStateName].currentDestination = folder;
          draft[modalStateName].currentDestinationShort = {
            key: folder.key.slice(0, 2),
            fsRootID: folder.fsRootID
          };
        });
      }
      return {...state};
    }

    case STORAGE_MNG_ADD_FILE_TO_WO_AUTO_CHOOSE_DESTINATION: {
      const modalStateName = getModalStateName(action.type);
      const {
        existed_symlink_root_directory,
        default_symlink_root_directory,
        existed_WO_root_directory,
        source_already_linked,
        default_copy_destination,
      } = action;
      const {initialDestination, currentDestination, currentCopyDestination} = state[modalStateName];
      if (initialDestination.key === currentDestination.key) {
        return produce(state, (draft) => {
          draft[modalStateName].existed_WO_root_directory = existed_WO_root_directory;
          draft[modalStateName].default_copy_destination = default_copy_destination;
          draft[modalStateName].source_already_linked = source_already_linked;
          draft[modalStateName].default_symlink_root_directory = default_symlink_root_directory;
          draft[modalStateName].isDirectoryAsRootDisabled = !!existed_symlink_root_directory;
          const symlinkRootDirectory = existed_symlink_root_directory || default_symlink_root_directory;
          const folder = {
            key: symlinkRootDirectory,
            fsRootID: (draft.fsRoots.find(i => symlinkRootDirectory.startsWith(i.fsRootName)) || {fsRootID: 0}).fsRootID
          };
          draft[modalStateName].initialDestination = folder;

          if (default_copy_destination) {
            draft[modalStateName].currentCopyDestination.key = default_copy_destination;
          }

          if(currentCopyDestination.fsRootID === folder.fsRootID) {
            draft[modalStateName].currentDestination = folder;
            draft[modalStateName].currentDestinationShort = {
              key: folder.key.slice(0, 2),
              fsRootID: folder.fsRootID
            };
          }
        });
      }
      return {...state};
    }

    case STORAGE_MNG_ADD_FILE_TO_WO_SET_AUTO_CHOOSE_DESTINATION: {
      const modalStateName = getModalStateName(action.type);
      const {existed_fs_entity, tail, full_file_name} = action.res;

      if (action.validationType === 'default_copy_destination') {
        return produce(state, (draft) => {
          draft[modalStateName].default_copy_destination = full_file_name || null;
          draft[modalStateName].currentCopyDestination.key = full_file_name || '';
          if (existed_fs_entity && existed_fs_entity.key) {
            draft[modalStateName].currentCopyDestination.contentID = existed_fs_entity.contentID;
            draft[modalStateName].default_copy_destination_v = {
              body: existed_fs_entity.key,
              tail,
              key: full_file_name,
              contentID: existed_fs_entity.contentID,
              fsRootID: existed_fs_entity.fsRootID
            };
          } else {
            draft[modalStateName].currentCopyDestination.contentID = '';
            draft[modalStateName].default_copy_destination_v = null;
          }
        });
      }

      return produce(state, (draft) => {
        draft[modalStateName].default_symlink_root_directory_body = existed_fs_entity.key;
        draft[modalStateName].default_symlink_root_directory_tail = tail;
        draft[modalStateName].currentDestination = {
          key: full_file_name,
          fsRootID: existed_fs_entity.fsRootID
        };
        draft[modalStateName].initialDestination = {
          key: full_file_name,
          fsRootID: existed_fs_entity.fsRootID
        };
      });
    }

    case STORAGE_MNG_ADD_FILE_TO_WO_AUTO_SAVE_REPLACEMENT: {
      const modalStateName = getModalStateName(action.type);
      const {
        replacementData,
        existed_WO_root_directory,
        source_already_linked,
        proxyCopyAvailable = false,
        show_file_selection,
        show_ingest_settings,
        ingest_operation_types,
        ingest_files,
        show_captions_send_to_qod
      } = action;

      return produce(state, (draft) => {
        draft[modalStateName].replacementData = {...replacementData, replacementWOMemfis: state[modalStateName].currentWOMemfis};
        draft[modalStateName].existed_WO_root_directory = existed_WO_root_directory;
        draft[modalStateName].source_already_linked = source_already_linked;
        draft[modalStateName].proxyCopyAvailable = proxyCopyAvailable;
        draft[modalStateName].showFileSelection = show_file_selection;
        draft[modalStateName].showIngestSettings = show_ingest_settings;
        draft[modalStateName].ingestFiles = ingest_files;
        draft[modalStateName].ingestOperationTypes = ingest_operation_types;
        draft[modalStateName].showCaptionsSendToQod = show_captions_send_to_qod;
      });
    }

    case STORAGE_MNG_ADD_FILE_TO_WO_RESET_DESTINATION: {
      const modalStateName = getModalStateName(action.type);
      const {key} = action;
      return produce(state, (draft) => {
        const folder = {
          key,
          fsRootID: (draft.fsRoots.find(i => key.startsWith(i.fsRootName)) || {fsRootID: 0}).fsRootID
        };
        draft[modalStateName].currentDestination = folder;
        draft[modalStateName].isDestinationChanged = false;
        draft[modalStateName].currentDestinationShort = {
          key: folder.key.slice(0, 2),
          fsRootID: folder.fsRootID
        };
      });
    }

    /*#endregion */

    case STORAGE_MNG_TOGGLE_SHOW_RENAME_AMAZON_FILE_MODAL: {
      return {...state, isShowShowRenameAmazonFileModal: action.isShow};
    }

    case STORAGE_MNG_ADD_FILE_TO_WO_SWITCH_MODAL: {
      const {show} = action;
      return {
        ...state,
        addFileToWoModalState: show ? {...state.addFileToWoModalState, show} : JSON.parse(JSON.stringify(initialState.addFileToWoModalState)),
        isLoadingModal: initialState.isLoadingModal,
        foldersInModal: initialState.foldersInModal,
        openedFoldersInModal: initialState.openedFoldersInModal,
      };
    }

    case STORAGE_MNG_ADD_FILE_TO_WO_NEW_FOLDER_CHECKBOX_CHANGE: {
      const {isChecked} = action;
      const modalStateName = getModalStateName(action.type);

      return produce(state, (draft) => {
        draft[modalStateName].isDirectoryAsRootChecked = isChecked;
      });
    }

    case STORAGE_MNG_ADD_FILE_TO_WO_CREATE_QOD_PROXY_CHECKBOX_CHANGE: {
      const {isChecked} = action
      const modalStateName = getModalStateName(action.type);

      return produce(state, (draft) => {
        draft[modalStateName].isCreateQODProxyChecked = isChecked;
      });
    }

    case STORAGE_MNG_ADD_FILE_TO_WO_SEND_CAPTIONS_TO_QOD_CHECKBOX_CHANGE: {
      const {isChecked} = action
      const modalStateName = getModalStateName(action.type);

      return produce(state, (draft) => {
        draft[modalStateName].checkedCaptionsSendToQod = isChecked;
      });
    }

    case STORAGE_MNG_ADD_FILE_TO_WO_CHANGE_DESTINATION_AVAILABLE: {
      const {isValid, message} = action;
      const modalStateName = getModalStateName(action.type);

      return produce(state, (draft) => {
        draft[modalStateName].isDestinationAvailable = isValid;
        draft[modalStateName].ifNotAvailableMessage = message;
      });
    }

    case STORAGE_MNG_ADD_FILE_TO_WO_SET_SELECTED_ROOT: {
      const modalStateName = getModalStateName(action.type);

      return produce(state, (draft) => {
        draft[modalStateName].selectedRoot = action.selection;
        draft[modalStateName].currentCopyDestination.fsRootID = action.selection.value;
        draft[modalStateName].currentCopyDestination.key = '';
        draft[modalStateName].currentCopyDestination.contentID = '';
        draft[modalStateName].isDirectoryAsRootChecked = null;
      });
    }

    case STORAGE_MNG_ADD_FILE_TO_WO_CLEAR : {
      const modalStateName = getModalStateName(action.type);

      return produce(state, (draft) => {
        draft[modalStateName] = {...initialState.addFileToWoModalState,
          isIngestScreen: false,
          isInnerFileIngestScreen: false,
          isFilesScreen: false,
          isInitialScreen: true,
          show: true,
          selectedRoot: state.addFileToWoModalState.selectedRoot,
          currentCopyDestination: state.addFileToWoModalState.currentCopyDestination};
      });
    }

    case STORAGE_MNG_ADD_FILE_TO_WO_SET_SUFFIX: {
      const modalStateName = getModalStateName(action.type);

      return produce(state, (draft) => {
        draft[modalStateName].addFileToWOSuffix = action.addFileToWOSuffix;
      });
    }

    case STORAGE_MNG_ADD_FILE_TO_WO_SET_SUFFIX_FILE: {
      const modalStateName = getModalStateName(action.type);

      return produce(state, (draft) => {
        draft[modalStateName].addFileToWOSuffixFile = action.addFileToWOSuffixFile;
      });
    }

    case STORAGE_MNG_ADD_FILE_TO_WO_RESET_AMB: {
      const modalStateName = getModalStateName(action.type);
      return produce(state, (draft) => {
        draft[modalStateName].AMBCurrent = draft[modalStateName].AMBInitial;
      });
    }

    case STORAGE_MNG_ADD_FILE_TO_WO_UPDATE_AMB: {
      const modalStateName = getModalStateName(action.type);
      let {new1, new3, new4} = action;
      const numericAMB = (Number(new4) * 8192) + (Number(new3) * 2) + Number(new1);

      return produce(state, (draft) => {
        draft[modalStateName].AMBCurrent = numericAMB;
      });
    }

    case STORAGE_MNG_ADD_FILE_TO_WO_SET_RUN_LKFS_ANALYSYS: {
      const modalStateName = getModalStateName(action.type);

      return produce(state, (draft) => {
        draft[modalStateName].runLKFSanalysis = action.runLKFSanalysis;
      });
    }

    case STORAGE_MNG_MEDIA_INFO_SHOW_MODAL: {
      return {
        ...state,
        isShowMediaInfoModal: action.isShow,
        isLoadingModal: initialState.isLoadingModal
      };
    }

    case STORAGE_MNG_UNLINK_SHOW_MODAL: {
      return {
        ...state,
        isShowUnlinkModal: action.isShow,
        isLoadingModal: initialState.isLoadingModal
      };
    }

    case STORAGE_MNG_SET_MEDIA_INFO: {
      return {
        ...state,
        mediaInfo: action.mediaInfo
      };
    }

    case STORAGE_MNG_UPLOAD_TO_ASPERA_SHOW_MODAL: {
      return {
        ...state,
        isShowUploadToAsperaModal: action.isShow,
        isLoadingModal: initialState.isLoadingModal
      };
    }

    case STORAGE_MNG_LOAD_FASPEX_ACCOUNTS: {
      return {
        ...state,
        faspexAccounts: action.faspexAccounts,
      };
    }

    case STORAGE_MNG_CREATE_SYMLINK_SHOW_MODAL: {
      return {
        ...state,
        isShowCreateSymlinkModal: action.isShow,
        isLoadingModal: initialState.isLoadingModal
      };
    }

    case STORAGE_MNG_CHECKSUM_REPORT_TOGGLE_MODAL: {
      return {
        ...state,
        isShowChecksumReportModal: action.isShow || initialState.isShowChecksumReportModal
      };
    }

    case STORAGE_MNG_CHANGE_SYMLINK_RELATIVE_STATUS: {
      const {item: {key}, relativeStatus} = action;
      return produce(state, (draft) => {
        if (draft.checkedItems.length && draft.checkedItems[0].key === key) {
          draft.checkedItems[0].relativeStatus = relativeStatus;
        }
        const displayedItem = draft.displayedItems.find(i => i.key === key);
        if (displayedItem) {
          displayedItem.relativeStatus = relativeStatus;
        }
      });
    }

    case STORAGE_MNG_SET_IS_CREATE_SYMLINK_CHECKED: {
      const {isCreateSymlinkChecked} = action;
      return produce(state, (draft) => {
        draft.addFileToWoModalState.isCreateSymlinkChecked = isCreateSymlinkChecked;
      });
    }

    case STORAGE_MNG_SET_REPLACEMENT_TYPE_VALUE: {
      const {replacementTypeValue} = action;
      return produce(state, (draft) => {
        draft.addFileToWoModalState.replacementTypeValue = replacementTypeValue;
      });
    }

    case STORAGE_MNG_SET_ATTACHED_VOLUMES: {
      const {checked} = action;
      return produce(state, (draft) => {
        draft.attachedVolumes = checked;
      });
    }

    case STORAGE_MNG_TOGGLE_ACTION_PROGRESS: {
      const {data} = action;
      return {
        ...state,
        actionInProgress: data ? {...data} : initialState.actionInProgress
      };
    }

    case STORAGE_MNG_TOGGLE_ACTION_SUBSCRIBE_POPUP: {
      const {data} = action;
      return {
        ...state,
        actionWatchInProgress: data ? {...data} : initialState.actionWatchInProgress
      };
    }

    case STORAGE_MNG_LOADING_ACTION_PROGRESS: {
      return {...state, loadingActionProgress: action.loading};
    }

    case STORAGE_MNG_SET_ACTION_PROGRESS: {
      const {actionID, progress, details} = action;
      return produce(state, (draft) => {
        if (draft.actionInProgress && draft.actionInProgress.actionID === actionID) {
          if (details) {
            draft.actionInProgress.details = details;
            if (details.progress) {
              draft.actionInProgress.progress = details.progress;
            } else {
              draft.actionInProgress.progress = progress;
            }
            if (details.actionStatus) {
              draft.actionInProgress.status = details.actionStatus;
            }
            if (details.action_requestor_name) {
              draft.actionInProgress.action_requestor_name = details.action_requestor_name;
            }
            if (details.created_on) {
              draft.actionInProgress.created_on = details.created_on;
            }
          } else {
          draft.actionInProgress.progress = progress;
        }
        }
        if (draft.actions) {
          let actionItem = draft.actions.find(a => a.actionID === actionID);
          if (!actionItem && draft.actionsChildren) {
            try {
              for (let [, actChildren] of Object.entries(draft.actionsChildren)) {
                if (actChildren) {
                  actionItem = actChildren.find(a => a.actionID === actionID);
                  if (actionItem) {
                    break;
                  }
                }
              }
            } catch {}
          }
          if (actionItem) {
            if (details) {
              actionItem.details = details;
              if (details.progress) {
                actionItem.progress = details.progress;
              } else {
                actionItem.progress = progress;
              }
              if (details.actionStatus) {
                actionItem.status = details.actionStatus;
              }
              if (details.action_requestor_name) {
                actionItem.action_requestor_name = details.action_requestor_name;
              }
              if (details.created_on) {
                actionItem.created_on = details.created_on;
              }
            } else {
              actionItem.progress = progress;
            }
          }
        }
      });
    }

    case STORAGE_MNG_TOGGLE_DELIVERY_RENAME_POPUP: {
      const {isShow, actionType, contentItems, woMemfis} = action;
      return {
        ...state,
        fileItemForSpecRename: isShow ? state.fileItemForSpecRename : initialState.fileItemForSpecRename,
        deliveryRenamePopupData: isShow ? {actionType, contentItems, woMemfis} : initialState.deliveryRenamePopupData
      };
    }

    case DIRECTORY_TREE_CHANGE_SEARCH_INPUT: {
      return {
        ...state,
        inputChangeDirectoryString: action.value || initialState.inputChangeDirectoryString,
      };
    }

    case DIRECTORY_TREE_IS_LOADING: {
      return {...state, loading: action.loading};
    }

    case DIRECTORY_TREE_FETCH_FOLDER: {
      const {folder, children, allParents} = action;
      return produce(state, (draft) => {
        draft.navigationIntoIsLoading = false;
        draft.displayedItemsInPopup = addChildrenIntoFolder(draft.displayedItems, folder, children, allParents);
        draft.availableActionsBySources = {};
      });
    }

    case STORAGE_MNG_ADD_TO_WO_SAVING: {
      return {...state, addToWoIngestData: action.data};
    }

    case STORAGE_MNG_ADD_TO_WO_INGEST_FILES: {
      const {row} = action;

      return produce(state, (draft) => {
        const {ingestFiles} = draft['addFileToWoModalState'];
        let file = ingestFiles.find(item => item.content_id === row.content_id);
        file.selected = row.selected;
      });
    }

    case STORAGE_MNG_TOGGLE_ATTACH_TO_WO_MODAL: {
      return {
        ...state,
        isLoadingModal: initialState.isLoadingModal,
        isShowAttachToWoModal: action.isShow || initialState.isShowAttachToWoModal
      };
    }

    case STORAGE_MNG_TOGGLE_ISSUE_SCREENSHOT_QOD_SHOW_MODAL: {
      return {
        ...state,
        isShowIssueScreenshotModal: action.isShow
      };
    }

    case STORAGE_MNG_CAPTIONS_SHOW_MODAL: {
      return {
        ...state,
        captionsIsShowModal: action.isShow
      };
    }

    case STORAGE_MNG_PSE_IS_LOADING: {
      return {
        ...state,
        isLoadingModal: action.loading
      };
    }

    case STORAGE_MNG_PSE_HDR_FILE_TYPE: {
      return {
        ...state,
        isPseHdrFileType: action.data && action.data.is_hdr ? action.data.is_hdr : null,
        pseWoMemfis: action.data && action.data.wo_memfis ? action.data.wo_memfis : null,
      };
    }

    case STORAGE_MNG_LKFS_SET_FRAME_RATE_VALUE: {
      const {data} = action;
      return produce(state, (draft) => {
        draft.LKFSModalState.frameRate = data.frame_rate || initialState.LKFSModalState.frameRate;
        draft.LKFSModalState.fileFrameRates = data.file_frame_rates || initialState.LKFSModalState.fileFrameRates;
        draft.LKFSModalState.dbFrameRate = data.db_frame_rate || initialState.LKFSModalState.dbFrameRate;
        draft.LKFSModalState.errorType = data.error_type || initialState.LKFSModalState.errorType;
        draft.LKFSModalState.isLKFSSubmit = data.error_type ? data.error_type === "success" : initialState.LKFSModalState.isLKFSSubmit;
        draft.LKFSModalState.errorQcProject = data.error_qc_project;
        draft.LKFSModalState.isEnable = data.is_enable;
      });
    }

    case STORAGE_MNG_CLEAR_FRAME_RATE: {
      return produce(state, (draft) => {
        draft.LKFSModalState.frameRate = initialState.LKFSModalState.frameRate;
        draft.LKFSModalState.dbFrameRate = initialState.LKFSModalState.dbFrameRate;
        draft.LKFSModalState.errorType = initialState.LKFSModalState.errorType;
        draft.LKFSModalState.newFrameRate = initialState.LKFSModalState.newFrameRate;
        draft.LKFSModalState.isUserFrameRate = initialState.LKFSModalState.isUserFrameRate;
        draft.LKFSModalState.isLKFSSubmit = initialState.LKFSModalState.isLKFSSubmit;
        draft.LKFSModalState.fileFrameRates = initialState.LKFSModalState.fileFrameRates;
        draft.LKFSModalState.isErrorNumberFrameRate = initialState.LKFSModalState.isErrorNumberFrameRate;
        draft.LKFSModalState.errorQcProject = initialState.LKFSModalState.errorQcProject;
        draft.LKFSModalState.isEnable = initialState.LKFSModalState.isEnable;
      });
    }

    case STORAGE_MNG_FRAME_RATE_UNCHECK_LKFS: {
      let {value} = action;

      return produce(state, (draft) => {
        if (value) {
          if (state.ingestOperationTypes) {
            const ingestOperationTypes = state.ingestOperationTypes;
            const keys = Object.keys(ingestOperationTypes);
            keys.forEach(k => {
              const operationTypes = ingestOperationTypes[k];
              for (const step of operationTypes.steps) {
                if ([1, 2, 8192].indexOf(step.id) > -1) {
                  step.value = false;
                }
              }
            });
            draft.ingestOperationTypes = ingestOperationTypes;
          } else {
            draft['addFileToWoModalState'].AMBCurrent = 0;
            draft['addFileToWoModalState'].runLKFSanalysis = 0;
          }

          draft.LKFSModalState.isLKFSSubmit = true;
        }
      });
    }

    case STORAGE_MNG_WO_STEPS: {
      return {
        ...state,
        woSteps: action.data,
        isWoStepsEmpty: !action.data || action.data.length === 0,
        showWoStepsAddToWo: action.data && action.data.length > 0
      };
    }

    case STORAGE_MNG_WO_STEPS_EMPTY: {
      return {
        ...state,
        isWoStepsEmpty: action.isEmpty
      };
    }


    case STORAGE_MNG_WO_STEPS_SHOW_MODAL: {
      return {
        ...state,
        woStepsIsShowModal: action.isShow,
      };
    }

    case STORAGE_MNG_WO_STEPS_ADD_TO_WO_SHOW_MODAL: {
      return {
        ...state,
        showWoStepsAddToWo: action.isShow,
      };
    }

    case STORAGE_MNG_WO_STEPS_ADD_TO_WO_IS_NEXT: {
      return {
        ...state,
        isWoStepsAddToWoNext: action.isNext,
      };
    }

    case STORAGE_MNG_WO_STEPS_ADD_TO_WO_IS_BACK: {
      return {
        ...state,
        isWoStepsAddToWoBack: action.isBack,
      };
    }

    case STORAGE_MNG_CLEAR_WO_STEPS: {
      return produce(state, (draft) => {
        draft.woSteps = initialState.woSteps;
        draft.woStepsIsShowModal = initialState.woStepsIsShowModal;
        draft.isWoStepsEmpty = initialState.isWoStepsEmpty;
        draft.woStepsIsSaving = initialState.woStepsIsSaving;
        draft.showWoStepsAddToWo = initialState.showWoStepsAddToWo;
        draft.isWoStepsAddToWoNext = initialState.isWoStepsAddToWoNext;
      });
    }

    case STORAGE_MNG_ACTIVATE_WO_STEP: {
      const {stepId, checked} = action;
      return produce(state, (draft) => {
        const step = draft.woSteps.find(step => step.step_id === stepId);
        if (step) {
          step.activate = checked;
        }
      });
    }

    case STORAGE_MNG_WO_STEPS_IS_SAVING: {
      return {
        ...state,
        woStepsIsSaving: action.loading
      };
    }

    case STORAGE_MNG_HDR_REPORT_SWITCH_MODAL: {
      return {
        ...state,
        isShowHdrReportModal: action.isShow || initialState.isShowHdrReportModal
      };
    }

    default:
      return state;
  }

}
