import React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import styled, {createGlobalStyle} from 'styled-components';
import {
  saveSettingsToStorage, getEmails, changeFilterValue, checkItemForLinking, setMemfisWOForDataItem,
  showSelectFolderModal, closeSelectFolderModal, checkManualUpload, changePageSize, changeSelectedItems,
  clearAllDownloadMng, restartAction, showUploadedFiles, completeDownloadForMultipleWOs, changeTargetRate,
  changePasswordForAutoDownloads, changePriorityForAutoDownloads, startDeliveryMemfisWO, processDelivery,
  closeDeliveryModal, refreshAssetData, getFiltersPresets, showIngestModal, setManualUpload, markCompleted,
  getAndShowIngestOperationTypes2, onSetOperationTypeFromAssetManager, getActionRequestors, changeAsperaLinkFileSize
} from './actions';
import PageComponent from '../../components/PageComponent';
import AppLayout from '../../layouts/AppLayout';
import Title from './components/Title';
import Toolbar from './components/Toolbar';
import Table from './components/table/Table';
import Paging from './components/table/Paging';
import Filters from '../../components/filters/Filters';
import qs from 'qs';
import {
  ALL_FILTERS, FILTER_DAYS_BACK_FROM, FILTER_WO_MEMFIS, FILTER_DOWNLOAD_TYPE, FILTER_DOWNLOAD_TYPE_AUTO,
  FILTER_DOWNLOAD_TYPE_UPLOAD, FILTER_HIDE_NAM_ASSETS, FILTER_HIDE_COMPLETED, FILTER_SEARCH, FILTER_CLIENT,
  isCompletedDownloadStatus, getDownloadStatusOrderNum, FILTERS_PRESET_ID
} from './constants';
import {
  getValidFilters, showLinkToMemfisModalForAutoDownloads, clearAllStorageMngForDownloadMng
} from '../StorageManager/actions';
import {LinkToMemfisWoAndIngest} from '../StorageManager/components/LinkToMemfisWoAndIngest';
import Loader from '../../components/Loader';
import {isForbiddenActionByUserPermissions} from '../../utils';
import {getAdvancedStatuses} from './utils';
import DaysBackFrom from './components/DaysBackFrom';
import SelectFolderModal from '../StorageManager/components/modal/SelectFolderModal';
import UploadedFilesModal from './components/modal/UploadedFilesModal';
import DeliveryModal from './components/modal/DeliveryModal';
import {
  FS_ACTION_TYPE__SET_DOWNLOAD_PRIORITY, FS_ACTION_TYPE__DELIVERY_MEMFIS_WO, FS_ACTION_TYPE__LINK_TO_MEMFIS_WO, FS_ACTION_TYPE__MK_DIRECTORY
} from '../StorageManager/constants';
import moment from 'moment';
import FiltersPresets from './components/FiltersPresets';
import IngestModal from '../StorageManager/components/modal/IngestModal';
import ReportBugButton from "../../components/ReportBugButton";
import MyActionsModal from "../StorageManager/components/modal/MyActionsModal";
import AsperaLinkFileSizeModal from "./components/modal/AsperaLinkFileSizeModal";

export const isCompletedStatus = link => {
  const statuses = getAdvancedStatuses(link);
  return statuses.length > 0 && isCompletedDownloadStatus(statuses[0]);
};

class DownloadManagerPage extends PageComponent {
  localStorageSettings = JSON.parse(localStorage.getItem('downloadSettings')) || {};
  sessionStorageSettings = JSON.parse(sessionStorage.getItem('downloadSettings')) || {};

  state = {
    tableSort: this.sessionStorageSettings.sorting || this.localStorageSettings.sorting || {
      field: 'updated_on_sec',
      direction: 'desc'
    },
    linkForEnterFileSize: null
  };

  componentDidMount() {
    localStorage.setItem('last_view', 'download');

    const {dispatch, history, urlFilters} = this.props;

    const storedPageSize = this.sessionStorageSettings.pageSize || this.localStorageSettings.pageSize;
    if (storedPageSize) {
      dispatch(changePageSize(storedPageSize, false));
    }

    const storedFiltersPresetId = parseInt(this.sessionStorageSettings[FILTERS_PRESET_ID] || this.localStorageSettings[FILTERS_PRESET_ID], 10);

    urlFilters && Object.keys(urlFilters).forEach(key => {
      if (![FILTER_WO_MEMFIS].includes(key)) {
        delete urlFilters[key];
      }
    });
    const validUrlFilters = getValidFilters(ALL_FILTERS, urlFilters);
    const woMemfisFromUrl = validUrlFilters[FILTER_WO_MEMFIS];
    if (woMemfisFromUrl) {
      Object.keys(validUrlFilters).forEach(key => {
        dispatch(changeFilterValue(history, key, validUrlFilters[key]));
      });
      dispatch(changeFilterValue(history, FILTER_DOWNLOAD_TYPE, [FILTER_DOWNLOAD_TYPE_AUTO, FILTER_DOWNLOAD_TYPE_UPLOAD]));
    } else {
      const storedFilters = this.sessionStorageSettings.filters || this.localStorageSettings.filters;
      if (storedFilters) {
        const validFilters = getValidFilters(ALL_FILTERS, storedFilters);
        Object.keys(validFilters).forEach(key => {
          if (key === FILTER_DAYS_BACK_FROM && validFilters[key] && typeof validFilters[key].fromDate === 'string') {
            let preparedFromDate;
            try {
              preparedFromDate = +validFilters[key].fromDate;
              if (isNaN(preparedFromDate) || !preparedFromDate) {
                preparedFromDate = +moment.utc(validFilters[key].fromDate, 'YYYY-MM-DDTHH:mm:ss.sssZ').format('x');
                if (isNaN(preparedFromDate) || !preparedFromDate) {
                  preparedFromDate = null;
                }
              }
            } catch(ignored) {
              preparedFromDate = null;
            }
            validFilters[key].fromDate = preparedFromDate;
          }
          dispatch(changeFilterValue(history, key, validFilters[key]));
        });
      }
    }

    dispatch(getFiltersPresets(storedFiltersPresetId, woMemfisFromUrl)).always(() => {
      dispatch(getEmails(history, true));
    });

    dispatch(getActionRequestors());
  }

  componentWillUnmount() {
    const {dispatch} = this.props;
    dispatch(showLinkToMemfisModalForAutoDownloads());
    dispatch(clearAllStorageMngForDownloadMng());
    dispatch(clearAllDownloadMng());
  }

  getStatusOrderNum = link => {
    const statuses = getAdvancedStatuses(link);
    if (statuses.length) {
      const {assetStatuses} = this.props.downloadManager;
      if (assetStatuses) {
        const assetStatus = assetStatuses.find(s => s.statusNames.includes(statuses[0]));
        if (assetStatus) {
          return assetStatus.ordinal + 1;
        }
      }
      return getDownloadStatusOrderNum(statuses[0]);
    }
    return 0;
  }

  compareItems = (ia, ib, field, direction) => {
    let a = field === 'status' ? this.getStatusOrderNum(ia) :
            field === 'client_name' ? (ia.clientNameAlt || ia[field]) :
            field === 'updated_on_sec' ? (ia[field] || Date.now()) : ia[field];
    let b = field === 'status' ? this.getStatusOrderNum(ib) :
            field === 'client_name' ? (ib.clientNameAlt || ib[field]) :
            field === 'updated_on_sec' ? (ib[field] || Date.now()) : ib[field];
    if (['target_rate_kbps', 'file_size_bytes'].includes(field)) {
      if (typeof a !== 'number') {
        a = -1;
      }
      if (typeof b !== 'number') {
        b = -1;
      }
    }
    if (field === 'status' && a === b) {
      a = typeof ia.Priority === 'number' ? ia.Priority : -1;
      b = typeof ib.Priority === 'number' ? ib.Priority : -1;
      if (a === b) {
        a = typeof ia.link_id === 'number' ? ia.link_id : -2;
        b = typeof ib.link_id === 'number' ? ib.link_id : -2;
      } else {
        const tmp = a;
        a = b;
        b = tmp;
      }
    }
    if (direction === 'desc') {
      const tmp = a;
      a = b;
      b = tmp;
    }
    if (typeof a === 'number') {
      if (field === 'status' && a === b) {
        a = ia.updated_on_sec > 0 ? ia.updated_on_sec : Date.now();
        b = ib.updated_on_sec > 0 ? ib.updated_on_sec : Date.now();
        //if (direction === 'asc') {
            const tmp = a;
            a = b;
            b = tmp;
        //}
      }
      return a - b;
    }
    if (typeof a === 'boolean') {
      return (a === b) ? 0 : a ? -1 : 1;
    }
    return (a || '').localeCompare(b || '');
  };

  getSortedRowsBy = (rows, field, direction) => {
    if (!field) {
      return rows;
    }

    const newRows = [...rows];

    newRows.sort((a, b) => {
      if (a.links && a.links.length === 1) {
        a = a.links[0];
      }
      if (b.links && b.links.length === 1) {
        b = b.links[0];
      }
      return this.compareItems(a, b, field, direction);
    });

    newRows.forEach(item => {
      if (item.files) {
        item.files = item.files.sort((ia, ib) => this.compareItems(ia, ib, field, direction));
      } else if (item.links) {
        if (item.links.length > 1) {
          item.links = item.links.sort((ia, ib) => this.compareItems(ia, ib, field, direction));
        } else if (item.parallel_ingest && item.parallel_ingest.links) {
          item.parallel_ingest.links = item.parallel_ingest.links.sort((ia, ib) => this.compareItems(ia, ib, field, direction));
        }
      }
    });

    return newRows;
  };

  handleChangeFilter = (name, value) => {
    const {dispatch, history} = this.props;
    dispatch(changeFilterValue(history, name, value));
  };

  handleApplyFilters = () => {
    const {dispatch, history} = this.props;
    dispatch(getEmails(history));
  };

  filterDataByProps = (filteredData, value, checkedProps) => {
      return filteredData.filter(item => {
        const link = item.links && item.links.length ? item.links[0] || {} : {};
        for (const key in link) {
          if (link.hasOwnProperty(key) && checkedProps.includes(key) &&
            String(link[key]).toLowerCase().includes(value.toLowerCase())) {
            return true;
          }
        }
        return false;
      });
  };

  isAppliedUsedFilters = () => {
    const {appliedUsedFiltersJson, usedFilters} = this.props.downloadManager;
    return appliedUsedFiltersJson === JSON.stringify(usedFilters);
  };

  getAppliedUsedFilters = () => {
    const {appliedUsedFiltersJson, usedFilters} = this.props.downloadManager;
    if (appliedUsedFiltersJson) {
      try {
        return JSON.parse(appliedUsedFiltersJson);
      } catch {}
    }
    return usedFilters;
  };

  getFilteredData = () => {
    const {data} = this.props.downloadManager;
    const usedFilters = this.getAppliedUsedFilters();

    let filteredData = [...data];

    if (usedFilters[FILTER_HIDE_COMPLETED]) {
      filteredData = filteredData.filter(item => {
        return !isCompletedStatus(item.links && item.links.length === 1 ? item.links[0] : item);
      });
    }

    if (usedFilters[FILTER_HIDE_NAM_ASSETS]) {
      filteredData = filteredData.filter(item => !item.is_nam);
    }

    if (usedFilters[FILTER_SEARCH]) {
      const checkedProps = [
        'status', 'ingest_status', 'ingest_status_advanced', 'file_name', 'file_size', 'WOMemfis', 'client_name',
        'requestID', 'PrimaryTitle', 'CategoryName', 'received_on', 'started_on', 'updated_on', 'creation_time',
        'msg_subject', 'msg_from', 'msg_to', 'msg_body', 'msg_link', 'clientNameAlt'
      ];
      filteredData = this.filterDataByProps(filteredData, usedFilters[FILTER_SEARCH], checkedProps);
    }

    if (usedFilters[FILTER_CLIENT]) {
      filteredData = this.filterDataByProps(filteredData, usedFilters[FILTER_CLIENT], ['client_name', 'clientNameAlt']);
    }

    return filteredData;
  };

  getFilteredManualData = () => {
    const {manualData} = this.props.downloadManager;
    const usedFilters = this.getAppliedUsedFilters();

    let filteredData = [...manualData];

    if (usedFilters[FILTER_SEARCH]) {
      const checkedProps = [
        'status', 'client_name', 'msg_subject', 'msg_from', 'msg_to', 'msg_body', 'msg_link'
      ];
      filteredData = filteredData.filter(item => {
        for (const key in item) {
          if (item.hasOwnProperty(key) && checkedProps.includes(key) &&
            String(item[key]).toLowerCase().includes(usedFilters[FILTER_SEARCH].toLowerCase())) {
            return true;
          }
        }
        return false;
      });
    }

    return filteredData;
  };

  filterUploadDataByProps = (filteredData, value, checkedProps) => {
    return filteredData.filter(item => {
      for (const key in item) {
        if (item.hasOwnProperty(key) && checkedProps.includes(key) &&
          String(item[key]).toLowerCase().includes(value.toLowerCase())) {
          return true;
        }
      }
      return false;
    });
  };

  getFilteredUploadData = () => {
    const {uploadData} = this.props.downloadManager;
    const usedFilters = this.getAppliedUsedFilters();

    let filteredData = [...uploadData];

    if (usedFilters[FILTER_HIDE_COMPLETED]) {
      filteredData = filteredData.filter(item => !isCompletedStatus(item));
    }

    if (usedFilters[FILTER_SEARCH]) {
      const checkedProps = [
        'status', 'ingest_status', 'ingest_status_advanced', 'file_name', 'file_size', 'WOMemfis', 'client_name'
      ];
      filteredData = this.filterUploadDataByProps(filteredData, usedFilters[FILTER_SEARCH], checkedProps);
    }

    if (usedFilters[FILTER_CLIENT]) {
      filteredData = this.filterUploadDataByProps(filteredData, usedFilters[FILTER_CLIENT], ['client_name']);
    }

    return filteredData;
  };

  handleHeaderCellClick = (field, direction) => {
    const tableSort = {field, direction};
    saveSettingsToStorage('sorting', tableSort)
    this.setState({tableSort});
  };

  onBlurWoMemfis = event => {
    const {dispatch, history} = this.props;
    if (event.target.value) {
      dispatch(changeFilterValue(history, FILTER_DOWNLOAD_TYPE, [FILTER_DOWNLOAD_TYPE_AUTO, FILTER_DOWNLOAD_TYPE_UPLOAD]));
    }
  };

  prepareFilters = () => {
    const {usedFilters} = this.props.downloadManager;
    const downloadTypes = usedFilters[FILTER_DOWNLOAD_TYPE];
    const preparedFilters = [...ALL_FILTERS];
    if (!Array.isArray(downloadTypes) || !downloadTypes.map(t => t.value || t).includes(FILTER_DOWNLOAD_TYPE_AUTO)) {
      const hideNamFilterIndex = preparedFilters.findIndex(filter => filter.name === FILTER_HIDE_NAM_ASSETS);
      if (hideNamFilterIndex >= 0) {
        preparedFilters.splice(hideNamFilterIndex, 1);
      }
    }
    const filterWoMemfis = preparedFilters.find(filter => filter.name === FILTER_WO_MEMFIS);
    if (filterWoMemfis) {
      filterWoMemfis.props = {
        onBlur: this.onBlurWoMemfis,
        onKeyDown: ({keyCode, target}) => keyCode === 13 && target.blur()
      };
    }
    const filterDaysBackFrom = preparedFilters.find(filter => filter.name === FILTER_DAYS_BACK_FROM);
    if (filterDaysBackFrom) {
      const filterValue = usedFilters[FILTER_DAYS_BACK_FROM];
      const {daysBack, fromDate} = filterValue;
      filterDaysBackFrom.field =
        <DaysBackFrom
          daysBack={daysBack}
          fromDate={fromDate}
          onChange={(name, value) => this.handleChangeFilter(FILTER_DAYS_BACK_FROM, {...filterValue, [name]: value})}
        />;
    }
    return preparedFilters;
  };

  handleClickLinkToMemfisWO = item => {
    const {dispatch} = this.props;
    dispatch(checkItemForLinking(item));
  };

  handleSuccessOfLinkToMemfisWO = (item, memfisWoID, destinationPath) => {
    const {dispatch} = this.props;
    dispatch(setMemfisWOForDataItem(item, memfisWoID, destinationPath));
  };

  handleClickSelectDownloadedFolder = (item, items) => {
    const {dispatch} = this.props;
    dispatch(showSelectFolderModal(item, items));
  };

  handleCloseSelectFolderModal = () => {
    const {dispatch} = this.props;
    dispatch(closeSelectFolderModal());
    dispatch(clearAllStorageMngForDownloadMng());
  };

  handleSelectDownloadedFolder = folder => {
    const {dispatch} = this.props;
    dispatch(checkManualUpload(folder));
  };

  handleChangeSelectedItems = items => {
    const {dispatch} = this.props;
    dispatch(changeSelectedItems(items));
  };

  handleRestartAction = actionId => {
    const {dispatch} = this.props;
    dispatch(restartAction(actionId));
  };

  handleCloseUploadedFilesModal = () => {
    const {dispatch} = this.props;
    dispatch(showUploadedFiles());
  };

  handleCompleteDownloadForMultipleWOs = item => {
    const {dispatch} = this.props;
    dispatch(completeDownloadForMultipleWOs(item));
  };

  handleChangeTargetRate = (link, newValue, onError) => {
    const {dispatch} = this.props;
    dispatch(changeTargetRate(link, newValue, onError));
  };

  handleChangePassword = (link, newValue, onSuccess, onError) => {
    const {dispatch} = this.props;
    dispatch(changePasswordForAutoDownloads(link, newValue, onSuccess, onError));
  };

  handleChangePriority = (link, newValue, onSuccess) => {
    const {dispatch} = this.props;
    dispatch(changePriorityForAutoDownloads(link, newValue, onSuccess));
  };

  handleMarkCompleted = (link) => {
    const {dispatch} = this.props;
    dispatch(markCompleted(link));
  };

  handleStartDeliveryFromModal = (woMemfisId, rootId) => {
    const {dispatch, history} = this.props;
    dispatch(startDeliveryMemfisWO(history, woMemfisId, rootId));
  };

  handleClickDeliveryOnInfoPanel = (deliveryMemfisWO, redirect, actionId) => {
    const {dispatch, history} = this.props;
    dispatch(processDelivery(history, deliveryMemfisWO, redirect, actionId));
  };

  handleClickSetOperationTypeOnInfoPanel = () => {
    const {dispatch} = this.props;
    dispatch(getAndShowIngestOperationTypes2());
  };

  handleStartIngestOnAssetManager = ingestData => {
    const {dispatch} = this.props;
    dispatch(onSetOperationTypeFromAssetManager(ingestData));
  };

  handleCloseDeliveryModal = () => {
    const {dispatch} = this.props;
    dispatch(closeDeliveryModal());
    dispatch(clearAllStorageMngForDownloadMng());
  };

  onChangeLinkFileSize = (asperaLink) => {
    this.setState({
      linkForEnterFileSize: asperaLink
    });
  }

  handleSaveAsperaLinkFileSizeModal = (fileSizeBytes) => {
    const {dispatch} = this.props;
    const {linkForEnterFileSize} = this.state;
    dispatch(changeAsperaLinkFileSize(linkForEnterFileSize, fileSizeBytes)).always((res) => {
      this.handleCloseAsperaLinkFileSizeModal()
    });
  }

  handleCloseAsperaLinkFileSizeModal = () => {
    this.setState({
      linkForEnterFileSize: null
    });
  }

  renderFiltersPresets = () => {
    const {dispatch, history, downloadManager, urlFilters} = this.props;
    const {loaders, filtersPresets, selectedFiltersPreset, usedFilters} = downloadManager;
    const usedFiltersJson = JSON.stringify(usedFilters);
    const isSavedCurrentPreset = !!selectedFiltersPreset && selectedFiltersPreset.filtersJson === usedFiltersJson;
    return [
      <>
        <FiltersPresets
          dispatch={dispatch}
          history={history}
          loading={loaders.getFiltersPresets}
          presets={filtersPresets}
          currentPreset={selectedFiltersPreset}
          disabledMenu={false}
          isSaved={isSavedCurrentPreset}
          isAdvancedMode={urlFilters.advanced === '1'}
        />
        <HR/>
      </>,
      isSavedCurrentPreset
    ];
  };

  renderFilters = () => {
    const {downloadManager} = this.props;
    const {usedFilters} = downloadManager;
    const isShowRefreshBtn = this.isAppliedUsedFilters();
    const [presets, isSavedCurrentPreset] = this.renderFiltersPresets();
    return (
      <Filters
        loading={false}
        filters={this.prepareFilters()}
        usedFilters={usedFilters}
        onChange={this.handleChangeFilter}
        applyLabel={isShowRefreshBtn ? 'Refresh' : 'Apply'}
        applyFilters={this.handleApplyFilters}
        isShowApplyBtnInHeaderWhenCollapsed={isShowRefreshBtn}
        title={`Filters${isSavedCurrentPreset ? '' : ' *'}`}
        bodyMd={12}
        filterTop={presets}
      />
    );
  };

  render() {
    const {dispatch, downloadManager, storageManager} = this.props;
    const {
      actionsUserPermissions, actionsUserPermissionsIsLoading, availableFsRootsIsLoading, availableManualMemfisWOs,
      finalDestinationIsLoading, availableManualMemfisWOsIsLoading, linkToMemfisWOIsSaving, isLoadingModal, isMyActionsModalOpen, LKFSModalState
    } = storageManager;
    const {
      lastCrawlTS, lastPackageTS, lastDownloadTS, usedFilters, currentPage, pageSize, selectedItems, loaders, minRate,
      maxRate, appliedWoMemfis, fileMakerURL, selectFolderModalItem, uploadFiles, notLockedRoots,
      memfisWoInDeliveryModal, isShowIngestModal, ingestOperationTypes, ingestWorkOrder, ingestFolder, ingestItem
    } = downloadManager;
    const data = [].concat(this.getFilteredData(), this.getFilteredManualData(), this.getFilteredUploadData());

    const {tableSort, linkForEnterFileSize} = this.state;
    const sortedData = this.getSortedRowsBy(data, tableSort.field, tableSort.direction);

    const pagedData = sortedData.slice(currentPage * pageSize, currentPage * pageSize + pageSize);

    const isForbiddenMakeDirectory = isForbiddenActionByUserPermissions(actionsUserPermissions, FS_ACTION_TYPE__MK_DIRECTORY);
    const isForbiddenDeliveryMemfisWO = isForbiddenActionByUserPermissions(actionsUserPermissions, FS_ACTION_TYPE__DELIVERY_MEMFIS_WO);
    const isForbiddenLinkToWO = isForbiddenActionByUserPermissions(actionsUserPermissions, FS_ACTION_TYPE__LINK_TO_MEMFIS_WO);
    const isForbiddenSetPriority = isForbiddenActionByUserPermissions(actionsUserPermissions, FS_ACTION_TYPE__SET_DOWNLOAD_PRIORITY);
    const isLoading = Object.keys(loaders).some(k => loaders[k]) || actionsUserPermissionsIsLoading ||
      availableFsRootsIsLoading || finalDestinationIsLoading || availableManualMemfisWOsIsLoading ||
      linkToMemfisWOIsSaving || Boolean(availableManualMemfisWOs && isLoadingModal);

    return (
      <AppLayout title="Asset Manager" className="download-manager-page" filters={this.renderFilters()}>
        <StyledDownloadManager className="container">
          {isMyActionsModalOpen &&
            <MyActionsModal
            dispatch={dispatch}
            storageManager={storageManager}
            />
          }
          {isLoading && !memfisWoInDeliveryModal ?
              <div>
                <Loader className="full-screen"/>
                <ReportBugButton isForModal className="loader-report-bug-btn"/>
              </div> : null}
          <div id="auto_download" className="tabcontent">
            <LinkToMemfisWoAndIngest
              dispatch={dispatch}
              storageManager={storageManager}
              requestedPage="download"
              isLoading={isLoading}
              linkToMemfisWOCallback={this.handleSuccessOfLinkToMemfisWO}
              onCompleteDownloadForMultipleWOs={this.handleCompleteDownloadForMultipleWOs}
            />
            {
              selectFolderModalItem ?
                <SelectFolderModal
                  dispatch={dispatch}
                  storageManager={storageManager}
                  onClose={this.handleCloseSelectFolderModal}
                  onConfirm={this.handleSelectDownloadedFolder}
                  categoryName={selectFolderModalItem.CategoryName}
                  rootName={selectFolderModalItem.rootName}
                  isSaving={Boolean(loaders.manualUpload || loaders.ingestOperationTypes)}
                /> : null
            }
            {
              uploadFiles ?
                <UploadedFilesModal
                  onClose={this.handleCloseUploadedFilesModal}
                  data={uploadFiles}
                /> : null
            }
            {
              isShowIngestModal ?
                <IngestModal
                  dispatch={dispatch}
                  data={ingestOperationTypes}
                  workOrder={ingestWorkOrder}
                  item={ingestFolder || ingestItem}
                  isSending={isLoading}
                  isLoading={isLoading}
                  onSetManualUpload={ingestFolder ? (item, ingestData) => dispatch(setManualUpload(item, ingestData)) : undefined}
                  onStartIngestFromAssetManager={this.handleStartIngestOnAssetManager}
                  onClose={() => dispatch(showIngestModal(false))}
                  lkfsModalState={LKFSModalState}
                /> : null
            }
            {
              memfisWoInDeliveryModal ?
                <DeliveryModal
                  dispatch={dispatch}
                  storageManager={storageManager}
                  onClose={this.handleCloseDeliveryModal}
                  onConfirm={this.handleStartDeliveryFromModal}
                  woMemfisId={memfisWoInDeliveryModal}
                  isSaving={loaders.startDeliveryMemfisWO || loaders.makeDirectory}
                  isForbiddenMakeDirectory={isForbiddenMakeDirectory}
                /> : null
            }
            {
              linkForEnterFileSize ?
                  <AsperaLinkFileSizeModal
                      onSave={this.handleSaveAsperaLinkFileSizeModal}
                      onClose={this.handleCloseAsperaLinkFileSizeModal}
                  /> : null
            }
            <Title
              lastCrawlTS={lastCrawlTS}
              lastPackageTS={lastPackageTS}
              lastDownloadTS={lastDownloadTS}
              woMemfis={appliedWoMemfis}
            />
            <Toolbar
              dispatch={dispatch}
              pageSize={pageSize}
            />
            <Table
              dispatch={dispatch}
              data={pagedData}
              fileMakerURL={fileMakerURL}
              minRate={minRate}
              maxRate={maxRate}
              onChangeTargetRate={this.handleChangeTargetRate}
              onChangePassword={this.handleChangePassword}
              onChangePriority={isForbiddenSetPriority ? undefined : this.handleChangePriority}
              onMarkCompleted={this.handleMarkCompleted}
              onDeliveryMemfisWO={isForbiddenDeliveryMemfisWO ? undefined : this.handleClickDeliveryOnInfoPanel}
              onSetOperationType={isForbiddenLinkToWO ? undefined : this.handleClickSetOperationTypeOnInfoPanel}
              onRefreshDeliveryData={() => dispatch(refreshAssetData())}
              sort={tableSort}
              onHeaderCellClick={this.handleHeaderCellClick}
              onClickLinkToMemfisWO={isForbiddenLinkToWO ? undefined : this.handleClickLinkToMemfisWO}
              onClickSelectDownloadedFolder={this.handleClickSelectDownloadedFolder}
              onSelectRow={this.handleChangeSelectedItems}
              selectedItems={selectedItems}
              onRestartAction={this.handleRestartAction}
              actionsIsLoading={loaders.getAssetData || loaders.getRootLockStatuses}
              notLockedRoots={notLockedRoots}
              isHideCompletedLinks={usedFilters[FILTER_HIDE_COMPLETED]}
              getFsRoot={rootName => rootName && (storageManager.fsRoots || []).find(i => i.fsRootName === rootName)}
              onChangeLinkFileSize={this.onChangeLinkFileSize}
            />
            <Paging
              dispatch={dispatch}
              currentPage={currentPage}
              pageSize={pageSize}
              total={data.length}
            />
          </div>
        </StyledDownloadManager>
        <GlobalStyled/>
      </AppLayout>
    );
  }
}

const GlobalStyled = createGlobalStyle`
  .download-manager-page {

    .filters-block {
      margin: 0 25px;

      @media (min-width: 768px) {
        display: flex;
        flex-wrap: wrap;

        >.form-group {
          width: 48%;

          &:nth-of-type(even) {
            margin-left: 2%;
          }
          &:nth-of-type(odd) {
            margin-right: 2%;
          }
        }
      }
    }

    .filters-btn-toolbar {
      margin: 0 -7px;
      padding-top: 0;
      border-top: none;
    }
  }
`;

const StyledDownloadManager = styled.div`
  .tabcontent {
    padding: 6px 15px;
  }
`;

const HR = styled.hr`
  margin: 18px -10px 0;
  width: calc(100% + 20px);
  display: block;
`;

const mapStateToProps = (state, ownProps) => {
  return {
    downloadManager: state.downloadManager,
    storageManager: state.storageManager,
    user: state.user,
    urlFilters: qs.parse(ownProps.location.search.substr(1))
  };
};

export default connect(
  mapStateToProps
)(withRouter(DownloadManagerPage));
