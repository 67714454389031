import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import WoCell from './cells/WoCell';
import FileNameCell from './cells/FileNameCell';
import {StatusCell} from './cells/StatusCell';
import {ClientCell} from './cells/ClientCell';
import {CheckboxCell} from '../../../../components/table/cells/CheckboxCell';
import {getStatusAndColor, parseStaleOrFailedStatus} from '../../utils';
import {isCompletedStatus} from '../../container';
import {formatDateFromUtcSeconds} from '../../../../utils';
import {getActionTypeIcon} from './infopanel/ActionsTree';
import _ from 'lodash';

class TableRow extends Component {
  renderRow(index, link, {group, inGroup, groupWithHiddenCompletedLinks}, groupId, links) {
    const {fileMakerURL, onClickLinkToMemfisWO, advanced, onClickSelectDownloadedFolder, isChecked, onChange, getFsRoot
    } = this.props;
    const {file_size, updated_on_sec, link_id} = link;
    let props, collapsingIconProps = {className: 'collapsing-icon'};
    if (group) {
      props = {
        className: `group${groupWithHiddenCompletedLinks ? ' group-with-hidden-completed-links' : ''}`
      };
      collapsingIconProps = {
        className: 'collapsing-icon collapsed',
        'data-toggle': 'collapse',
        'data-target': `.group-${groupId}`
      };
    } else if (inGroup) {
      props = {
        className: `in-group collapse group-${groupId}${groupWithHiddenCompletedLinks ? ` group-with-hidden-completed-links${isCompletedStatus(link) ? ' hidden-completed-link' : ''}` : ''}${link.hide_on_list ? ' hidden' : ''}`
      };
    }
    const fileSizeCell = file_size ? file_size : (
        <span
            className="file-size-btn-cell"
            title={'Set size'}
            onClick={() => this.props.onChangeLinkFileSize(link)}
        >
        Set size
      </span>
    );
    return (
      <StyledRow {...props} key={`${index}-${(link.requestID || link.mam_file_id || link.file_id || 'no-file')}`}>
        <CheckboxCell
          row={link}
          isChecked={isChecked}
          onChange={onChange}
        />
        <td {...collapsingIconProps}><span className="fas fa-angle-down"/></td>
        <td>
          <WoCell
            link={link}
            onClickLinkToMemfisWO={onClickLinkToMemfisWO}
            fileMakerURL={fileMakerURL}
          />
        </td>
        <FileNameCell
          link={link}
          inPackage={inGroup}
          onClickSelectDownloadedFolder={onClickSelectDownloadedFolder}
          getFsRoot={getFsRoot}
          links={links}
        />
        <ClientCell link={link}/>
        <td>{fileSizeCell}</td>
        <td>{formatDateFromUtcSeconds(updated_on_sec)}</td>
        <StatusCell row={link}/>
        {
          advanced ? (
            <td>
              {link_id > 0 ? <a href={`/notifications/${link_id}`}>View Info</a> : null}
            </td>
          ) : null
        }
      </StyledRow>
    );
  }

  renderManualRow(row) {
    const {advanced, isChecked, onChange} = this.props;
    const {client_name, message_id} = row;
    return (
      <StyledRow key={`manual-${message_id}`}>
        <CheckboxCell
          row={row}
          isChecked={isChecked}
          onChange={onChange}
        />
        <td className="collapsing-icon"><span/></td>
        <td/>
        <FileNameCell link={row}/>
        <td>{client_name}</td>
        <td/>
        <td/>
        <StatusCell row={row}/>
        {advanced ? <td/> : null}
      </StyledRow>
    );
  }

  renderUploadRow(row) {
    return this.renderRow(0, row, {group: false, inGroup: false});
  }

  getRootName = item => {
    return (item.file_name || '').split(/[\\/]/)[0] || '';
  };

  renderPackageRow(groupId, packageItem, links, isHideCompletedLinks) {
    const rootName = this.getRootName(packageItem);
    const groupWithHiddenCompletedLinks = !!isHideCompletedLinks && links.some(isCompletedStatus);
    const packageActionTypeIcon = _.minBy(links.map(({mainType, actionType}) => (getActionTypeIcon(mainType, actionType))), 'iconPriority');
    const {mainType, actionType} = packageActionTypeIcon;
    return (
      <Fragment>
        <StyledRow className={`above-group collapse group-${groupId}`}>
          <td colSpan="100"/>
        </StyledRow>
        {this.renderRow(0, {...packageItem, rootName, mainType, actionType}, {group: true, inGroup: false, groupWithHiddenCompletedLinks}, groupId, links)}
        {links.map((link, index) => this.renderRow(index + 1, {...link, rootName}, {group: false, inGroup: true, groupWithHiddenCompletedLinks}, groupId, links))}
      </Fragment>
    );
  }

  render() {
    const {row, isHideCompletedLinks} = this.props;
    if (row.is_manual) {
      return this.renderManualRow(row);
    }

    if (row.upload_id) {
      return this.renderUploadRow(row);
    }

    if (!row.links) {
      return null;
    }

    if (row.links.length === 1) {
      if (row.parallel_ingest && row.parallel_ingest.links) {
        let exclamationColor;
        const mainIngestStatuses = getStatusAndColor(row.links[0]);
        const noProblemStatusInMainIngest = !mainIngestStatuses.some(s => {
          const temp = parseStaleOrFailedStatus(s.status);
          return Boolean(temp && temp.length > 2);
        });
        if (noProblemStatusInMainIngest) {
          for (let pl of row.parallel_ingest.links) {
            const statuses = getStatusAndColor(pl);
            const problemStatusColor = statuses.map(s => {
              const temp = parseStaleOrFailedStatus(s.status);
              return (temp && temp.length > 2) ? s.color : null;
            }).filter(Boolean);
            if (problemStatusColor.length) {
              exclamationColor = problemStatusColor[0];
            }
          }
        }
        const packageItem = {
          ...row.links[0],
          useIngestStatus: true,
          ingest_status_advanced: row.ingest_status_advanced,
          ingest_status: row.ingest_status,
          exclamationColor
        };
        return this.renderPackageRow(row.groupId, packageItem, row.parallel_ingest.links);
      }

      const singleRowLink = {
        ...row.links[0],
//        ingest_status_advanced: row.ingest_status_advanced,
//        ingest_status: row.ingest_status,
        isSingleRow: true,
        rootName: this.getRootName(row.links[0])
      };
      return this.renderRow(0, singleRowLink, {group: false, inGroup: false}, null, [singleRowLink]);
    }

    const deletedLinks = row.links.filter(link => link.is_deleted);
    const isPartiallyDeleted = deletedLinks.length > 0 && row.links.length > deletedLinks.length;
    const packageItem = {
      ...row,
      useIngestStatus: true,
      is_deleted: row.is_deleted || (deletedLinks.length > 0 && row.links.length === deletedLinks.length),
      isPartiallyDeleted
    };
    let {groupId} = packageItem;
    if (!groupId) {
      groupId = String(Math.random()).replace(/[^0-9]/, '');
    }
    return this.renderPackageRow(groupId, packageItem, row.links, isHideCompletedLinks);
  }
}

const StyledRow = styled.tr`
  td {
    &.collapsing-icon {
      width: 25px;

      span {
        padding: 0 2px;
        transition: transform 0.2s ease 0s;
        will-change: transform;
      }
    }
  }

  &:not(.group) > td.collapsing-icon > span {
    visibility: hidden;
  }

  &.group {

    td.collapsing-icon {
      cursor: pointer;

      &:hover {
        background-color: rgba(0, 0, 0, 0.1);
      }
    }

    tr.above-group.collapsing + &, tr.above-group.collapse.in + & {
      td.collapsing-icon {
        background-color: #ccc;

        span {
          transform: rotate(-180deg);
        }
      }

      * {
        text-shadow: 0 0 0 #282828;
      }
    }
  }

  &.in-group.collapsing, &.in-group.collapse.in {
    > td.collapsing-icon {
      background-color: #6a7ae0;
    }
  }

  &.in-group.hidden-completed-link {
    display: none;
  }

  &.above-group {
    display: none !important;
  }

  .file-size-btn-cell {
    cursor: pointer;
    color: #ff0000;
    &:hover {
      text-decoration: underline;
    }
  }
`;

TableRow.propTypes = {
  row: PropTypes.object.isRequired,
  getFsRoot: PropTypes.func,
  onClickSelectDownloadedFolder: PropTypes.func,
  onClickLinkToMemfisWO: PropTypes.func,
  fileMakerURL: PropTypes.string,
  advanced: PropTypes.bool.isRequired,
  isChecked: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired
};

export default TableRow;