import React from 'react';
import {useDispatch} from 'react-redux';
import {Button, DropdownButton, MenuItem, Tooltip, OverlayTrigger} from 'react-bootstrap';
import {confirmAlert} from 'react-confirm-alert';
import {isForbiddenAction, isHiddenActionBtn} from '../../../../utils';
import {
  isDeletedStatus,
  isRootItem, isNearlineDrive,
  areAllCheckedItemsApplicableToLKFS, areAllCheckedItemsApplicableToCAPTIONS,
} from '../../utils';
import {
  switchLKFSModal,
  generateMd5ChecksumOrShowModal,
  generateSha1ChecksumOrShowModal,
  readFile,
  executeEdgePassAction,
  showMediaInfoModal,
  getCopyData,
  showChecksumReportModal,
  deleteSymlink,
  checkSpecSymbolsOrGetRenameData,
  renameSpecSymbols,
  toggleAttachToWoModal,
  showCaptionsModal,
  showPseModal,
  showMetafierModal,
  toggleIssueScreenshotToQodModal,
  switchHdrReportModal
} from '../../actions';
import {
  DISPLAY_CATEGORY_INIT_CONFIRM_DELETION,
  FS_ACTION_TYPE__LKFS_ANALYSIS_PORTAL,
  FS_ACTION_TYPE__MD5_CHECKSUM,
  FS_ACTION_TYPE__SHA1_CHECKSUM,
  FS_ACTION_TYPE__READ_FILE,
  FS_ACTION_TYPE__EDGE_PASS_PORTAL,
  FS_ACTION_TYPE__SCREEN_TO_QOD_PORTAL,
  FS_ACTION_TYPE__CHECKSUM_REPORT,
  FS_ACTION_TYPE__DELETE_SYMLINK_PORTAL,
  FS_ACTION_TYPE__ATTACH_FILE_QOD,
  isSymlinkLive,
  isSymlinkDeleted, FS_ACTION_TYPE__CAPTIONS,
} from '../../constants';

export const isInitConfirmDeletion = ({displayCategory}) => {
  return displayCategory === DISPLAY_CATEGORY_INIT_CONFIRM_DELETION;
};

export const isAllowedFileAction = ({actionTypes}, actionTypeName, items) => {
  if (items.length === 0) {
    return false;
  }
  const re = /(?:\.([^.]+))?$/;
  const itemName = items.length === 1 && items[0].key;
  const isDirectory = items.length === 1 && items[0].isDirectory;
  return !isDirectory && (((actionTypes || {})[actionTypeName] || {}).allowedFileExtensions || []).some(item => item === re.exec(itemName)[0].toLowerCase());
};

export const isAllowedFileReadAction = ({extensionSettings}, files) => {
  const re = /(?:\.([^.]+))?$/;
  if (extensionSettings && extensionSettings.allowReading) {
    const allowedFileExtensions = Object.keys(extensionSettings.allowReading);
    return files.every(({key}) => {
      const ext = re.exec(key)[0].toLowerCase();
      if (ext === "" && extensionSettings.allowReading[ext].length > 0) {
        return true;
      }
      return allowedFileExtensions.includes(ext);
    });
  }
  return false;
};

export const renderActionButton = (props) => {
  const {
    forInfoPanel, title, onClick, iconClassName, allowAction, forbiddenActionReason, text,
    items, actionTypeForSpecSymbols, dispatch
  } = props;

  if (forInfoPanel && !allowAction) {
    return null;
  }

  const handleClick = !allowAction ? undefined : () => {
    if (dispatch && actionTypeForSpecSymbols && items && items.length) {
      const woMemfis = items.length === 1 ? items[0].memfisWoID : null;
      dispatch(checkSpecSymbolsOrGetRenameData(actionTypeForSpecSymbols, items, woMemfis))
        .then(res => {
          const {needs_rename, rename_mapping/*, delivery_rename_available*/} = res;
          if (!needs_rename || !rename_mapping || !Object.keys(rename_mapping).length) {
            onClick();
          } else {
            confirmAlert({
              childrenElement: () =>
                <div className="custom-msg" style={{maxHeight: '80vh', overflowY: 'auto'}}>
                  Needs to rename the following content to continue:
                  <ul>
                    {Object.entries(rename_mapping).map(([k, v], i) => (
                      <li key={i} style={{marginTop: '4px', fontSize: '12px'}}>
                        <span style={{opacity: 0.8}}>{k}</span>
                        <span style={{margin: '0 7px'}}><i className="fa-duotone fa-square-right"></i></span>
                        <span>{v}</span>
                      </li>
                    ))}
                  </ul>
                </div>,
              buttons: [{
                label: 'Cancel'
              }, {
                label: 'Rename&Continue',
                onClick: () => {
                  dispatch(renameSpecSymbols(actionTypeForSpecSymbols, items, onClick));
                }
              }]
            });
          }
        });
    } else {
      onClick();
    }
  };

  const btn = (
    <Button
      className={!allowAction ? 'disabled' : undefined}
      onClick={handleClick}
      title={title}
    >
      { text ? text : '' }
      { iconClassName && <i className={iconClassName}/> }
    </Button>
  );
  if (forbiddenActionReason) {
    return (
      <OverlayTrigger
        placement="bottom"
        overlay={
          <Tooltip id="btn-action-tooltip">
            {forbiddenActionReason}
          </Tooltip>
        }
      >
        {btn}
      </OverlayTrigger>
    );
  }
  return btn;
};

export const CaptionsButton = (props) => {
  if (isHiddenActionBtn(FS_ACTION_TYPE__CAPTIONS)) {
    return null;
  }
  const {forInfoPanel, dispatch, storageManager, items} = props;
  const {extensionSettings} = storageManager;
  if (extensionSettings) {
    const {allowCaptions} = extensionSettings;
    if (allowCaptions) {
      const whitelist = Object.keys(allowCaptions).map((extWithDot) => extWithDot.replace('.', ''));
      const allowAction = !isForbiddenAction(FS_ACTION_TYPE__CAPTIONS, (items[0] || {}).fsRootID) &&
        areAllCheckedItemsApplicableToCAPTIONS(items, whitelist) && !isInitConfirmDeletion(storageManager);
      return renderActionButton({
        title: 'Convert to VTT',
        onClick: () => dispatch(showCaptionsModal(true)),
        iconClassName: 'fa-light fa-closed-captioning',
        allowAction,
        forInfoPanel,
      });
    }
  }
  return null;
}

export const LKFSButton = (props) => {
  if (isHiddenActionBtn(FS_ACTION_TYPE__LKFS_ANALYSIS_PORTAL)) {
    return null;
  }
  const {forInfoPanel, dispatch, storageManager, items} = props;
  const {extensionSettings} = storageManager;
  if (extensionSettings) {
    const {allowLKFS, allowLKFSExtended} = extensionSettings;
    const whitelist = Object.keys(allowLKFS)
      .map((extWithDot) => extWithDot.replace('.', ''));
    if (items[0] && items[0].clientName != null && allowLKFSExtended) {
      for (let ext of Object.keys(allowLKFSExtended)) {
        if (whitelist.includes(ext)) {
          continue;
        }
        let element = allowLKFSExtended[ext].find(x => x.clientName === items[0].clientName);
        if (element) {
          whitelist.push(ext.replace('.', ''));
        }
      }
    }
    const allowAction = !isForbiddenAction(FS_ACTION_TYPE__LKFS_ANALYSIS_PORTAL, (items[0] || {}).fsRootID)
      && areAllCheckedItemsApplicableToLKFS(items, whitelist) && !isInitConfirmDeletion(storageManager);
    return renderActionButton({
      title: 'LKFS',
      onClick: () => switchLKFSModal(dispatch, true, items),
      iconClassName: 'fa-regular fa-waveform',
      allowAction,
      forInfoPanel,
    });
  }
  return null;
};

export const GenerateChecksumButtons = (props) => {
  const isHiddenMd5Btn = isHiddenActionBtn(FS_ACTION_TYPE__MD5_CHECKSUM);
  const isHiddenSha1Btn = isHiddenActionBtn(FS_ACTION_TYPE__SHA1_CHECKSUM);
  const isHiddenCsRepBtn = isHiddenActionBtn(FS_ACTION_TYPE__CHECKSUM_REPORT);
  if (isHiddenMd5Btn && isHiddenSha1Btn && isHiddenCsRepBtn) {
    return null;
  }

  const {forInfoPanel, dispatch, storageManager, items} = props;
  const {availableActionsBySources} = storageManager;
  const isNotConfirmDeletionDisplay = !isInitConfirmDeletion(storageManager);
  const isSelectedItemToChecksum = items.length === 1 && !items[0].isDirectory && isNotConfirmDeletionDisplay;
  const isSelectedItemsToChecksumReport = items.length > 1 ? items.every(item => item.fsRootID === items[0].fsRootID) &&
    Object.keys(availableActionsBySources).includes(FS_ACTION_TYPE__CHECKSUM_REPORT) && isNotConfirmDeletionDisplay :
      isSelectedItemToChecksum;
  const allowMd5 = isHiddenMd5Btn ? null : isSelectedItemToChecksum;
  const allowSha1 = isHiddenSha1Btn ? null : isSelectedItemToChecksum;
  const allowCsRep = isHiddenCsRepBtn ? null : isSelectedItemsToChecksumReport;
  const allowAnyAction = allowMd5 || allowSha1 || allowCsRep;

  if (forInfoPanel && !allowAnyAction) {
    return null;
  }

  const md5Btn = allowMd5 != null && renderActionButton({
    title: "Checksum MD5",
    onClick: () => dispatch(generateMd5ChecksumOrShowModal()),
    iconClassName: "fa-regular fa-binary-circle-check",
    allowAction: !!allowMd5,
    forInfoPanel,
  });
  const sha1Btn = allowSha1 != null && renderActionButton({
    title: "Checksum SHA1",
    onClick: () => dispatch(generateSha1ChecksumOrShowModal()),
    iconClassName: "fa-regular fa-binary-circle-check",
    allowAction: !!allowSha1,
    forInfoPanel,
  });
  const csRepBtn = allowCsRep != null && renderActionButton({
    title: "Checksum Report",
    onClick: () => dispatch(showChecksumReportModal()),
    iconClassName: "fa-regular fa-binary-circle-check",
    allowAction: !!allowCsRep,
    forInfoPanel,
  });

  return (
    <DropdownButton
      id="dropdown--link-to-wo--button"
      title={<i className="fa-regular fa-binary-circle-check" title="Generate Checksum"/>}
      disabled={!isSelectedItemToChecksum && !isSelectedItemsToChecksumReport}
    >
      {md5Btn ?
        <MenuItem eventKey="generate-md5-checksum">
          {md5Btn}
        </MenuItem> : null
      }
      {sha1Btn ?
        <MenuItem eventKey="generate-sha1-checksum">
          {sha1Btn}
        </MenuItem> : null
      }
      {csRepBtn ?
        <MenuItem eventKey="create-checksum-report">
          {csRepBtn}
        </MenuItem> : null
      }
    </DropdownButton>
  );
};

export const DownloadButton = (props) => {
  if (isHiddenActionBtn(FS_ACTION_TYPE__READ_FILE)) {
    return null;
  }
  const {forInfoPanel, dispatch, storageManager, items} = props;
  const isSingleFolder = items.length === 1 && items[0].isDirectory && !isRootItem(items[0]) && !isDeletedStatus(items[0]);
  const isFiles = !!items.length && items.every(item => !item.isDirectory && !isDeletedStatus(item));
  const allowAction = (isSingleFolder || (isFiles && isAllowedFileReadAction(storageManager, items))) &&
    !isInitConfirmDeletion(storageManager) &&
    !isForbiddenAction(FS_ACTION_TYPE__READ_FILE, items[0].fsRootID);
  return renderActionButton({
    title: `Download ${isSingleFolder ? 'Folder' : isFiles ? `File${items.length > 1 ? 's' : ''}` : ''}`,
    onClick: () => dispatch(readFile()),
    iconClassName: 'fa-regular fa-fw fa-download',
    allowAction,
    forInfoPanel,
  });
};

export const AttachToWoButton = (props) => {
  if (isHiddenActionBtn(FS_ACTION_TYPE__ATTACH_FILE_QOD)) {
    return null;
  }
  const {forInfoPanel, dispatch, storageManager, items} = props;
  const allowAction = items.length === 1 && !items[0].isDirectory && items[0].contentID > 0 && !isDeletedStatus(items[0]) &&
    isAllowedFileAction(storageManager, FS_ACTION_TYPE__ATTACH_FILE_QOD, items) &&
    !isInitConfirmDeletion(storageManager) &&
    !isForbiddenAction(FS_ACTION_TYPE__ATTACH_FILE_QOD, items[0].fsRootID);
  return renderActionButton({
    title: 'Attach file to QOD Work Order',
    onClick: () => dispatch(toggleAttachToWoModal(true)),
    iconClassName: 'fa-regular fa-fw fa-paperclip',
    allowAction,
    forInfoPanel,
  });
};

export const PSEButton = (props) => {
  const {forInfoPanel, dispatch, items, storageManager} = props;
  const isAllowedPSEAction = isAllowedFileAction(storageManager, 'PSE_ANALYSIS_PORTAL', items) &&
    items[0].contentID > 0 && !!items[0].memfisWoID;
  return renderActionButton({
    title: "PSE",
    onClick: () => dispatch(showPseModal(true)),
    iconClassName: "far fa-lightbulb-on",
    allowAction: !!isAllowedPSEAction,
    forInfoPanel,
  });
};

export const MediaInfoButton = (props) => {
  const {forInfoPanel, dispatch, items, storageManager} = props;
  const isAllowedMediaInfoButton = isAllowedFileAction(storageManager, 'MEDIAINFO', items) &&
    !items[0].isDirectory && items[0].contentID > 0;
  return renderActionButton({
    title: "Media Info",
    onClick: () => dispatch(showMediaInfoModal(true)),
    iconClassName: "fa-regular fa-circle-info",
    allowAction: !!isAllowedMediaInfoButton,
    forInfoPanel,
  });
};

export const CopyToQodButton = (props) => {
  const {forInfoPanel, dispatch, items, storageManager} = props;
  const {copyToQodModalState: {copyToQodInfo, copyToQodInfoList}} = storageManager;

  const isQodItemSelected = copyToQodInfo && copyToQodInfo.isProxyCopyAvailable && copyToQodInfo.woMemfis && copyToQodInfo.isWOMemfisQOD;
  const isQodItemSelectedNew = copyToQodInfoList && copyToQodInfoList.length > 0 ? copyToQodInfoList.find(item => item.isProxyCopyAvailable && item.woMemfis && item.isWOMemfisQOD) : null;

  const allowCopyToQod = items.length === 1 && items[0].contentID > 0 && (isQodItemSelected || isQodItemSelectedNew);
  return renderActionButton({
    title: "QOD Proxy Manage",
    onClick: () => dispatch(getCopyData(items[0], true)),
    iconClassName: "fa-regular fa-video-plus",
    allowAction: !!allowCopyToQod,
    forInfoPanel,
  });
};

export const DeleteSymlinkButton = (props) => {
  if (isHiddenActionBtn(FS_ACTION_TYPE__DELETE_SYMLINK_PORTAL)) {
    return null;
  }
  const {forInfoPanel, dispatch, storageManager, items} = props;
  const isSingleSymlinkItem = items.length === 1 && items[0].isSymlink;
  const allowAction = !isInitConfirmDeletion(storageManager)
    && isSingleSymlinkItem && !isSymlinkDeleted(items[0])
    && !isForbiddenAction(FS_ACTION_TYPE__DELETE_SYMLINK_PORTAL, (items[0] || {}).fsRootID);
  return renderActionButton({
    title: 'Delete Symlink',
    onClick: () => dispatch(deleteSymlink(items[0])),
    iconClassName: `fa-regular fa-fw fa-link${isSingleSymlinkItem && isSymlinkLive(items[0]) ? '' : '-simple'}-slash`,
    allowAction,
    forInfoPanel,
  });
};

export const MetafierButton = (props) => {
  const {forInfoPanel, dispatch, storageManager, items} = props;

  const {extensionSettings} = storageManager;
  let allowAction = false;
  if (extensionSettings) {
    const {metafierExtensions} = extensionSettings;
    allowAction = items.length === 1 && metafierExtensions != null && Object.keys(metafierExtensions).some(ext => items[0].key.endsWith(ext));
  }

  return renderActionButton({
    title: "Metafier",
    onClick: () => dispatch(showMetafierModal(true)),
    iconClassName: "fa-brands fa-stack-overflow",
    allowAction: allowAction,
    forInfoPanel,
  });
};

export const EdgePassButton = (props) => {
  if (isHiddenActionBtn(FS_ACTION_TYPE__EDGE_PASS_PORTAL)) {
    return null;
  }
  const {forInfoPanel, dispatch, storageManager, items} = props;

  const {extensionSettings, fsRoots} = storageManager;
  let allowAction = false;
  if (extensionSettings) {
    const {edgePassExtensions} = extensionSettings;
    allowAction = items.length === 1 && edgePassExtensions != null
        && Object.keys(edgePassExtensions).some(ext => items[0].key.endsWith(ext)) && items[0].memfisWoID
        && !isNearlineDrive(items[0], fsRoots); // and NO button on Nearline drive
  }

  return renderActionButton({
    title: "Edge Pass",
    onClick: () => dispatch(executeEdgePassAction(items && items.length==1 ? items[0].memfisWoID : null, true)),
    iconClassName: "fa-solid fa-draw-polygon",
    allowAction: allowAction,
    forInfoPanel,
  });
};

export const ScreenToQodButton = (props) => {
  if (isHiddenActionBtn(FS_ACTION_TYPE__SCREEN_TO_QOD_PORTAL)) {
    return null;
  }
  const {forInfoPanel, dispatch, storageManager, items} = props;

  const {extensionSettings, fsRoots} = storageManager;
  let allowAction = false;
  if (extensionSettings) {
    const {screenToQodExtensions} = extensionSettings;
    allowAction = items.length === 1 && screenToQodExtensions != null && items[0].officeFlag != 2   // No NY for now !!!
        && Object.keys(screenToQodExtensions).some(ext => items[0].key.endsWith(ext)) && items[0].memfisWoID
        && !isNearlineDrive(items[0], fsRoots); // and NO button on Nearline drive
  }

  return renderActionButton({
    title: "Create Screenshot",
    onClick: () => dispatch(toggleIssueScreenshotToQodModal(true)),
    iconClassName: "fa-regular fa-photo-film",
    allowAction: allowAction,
    forInfoPanel,
  });
};

export const HdrReportButton = (props) => {
  const {dispatch, forInfoPanel, items, storageManager} = props;
  const isAllowedHdrReportButton = isAllowedFileAction(storageManager, FS_ACTION_TYPE__LKFS_ANALYSIS_PORTAL, items)
      && !items[0].isDirectory
      && items[0].contentID > 0;
  return renderActionButton({
    title: "HDR Report",
    onClick: () => dispatch(switchHdrReportModal(true)),
    iconClassName: "fa-solid fa-eye-low-vision",
    allowAction: !!isAllowedHdrReportButton,
    forInfoPanel,
  });
};
