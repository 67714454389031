import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Modal, Button, ControlLabel, FormControl} from 'react-bootstrap';
import Select from 'react-select';
import RndModal from '../../../../components/modals/RndModal';
import {MAX_FILENAME_LENGTH_TO_UPLOAD, parseFileExtension} from "../../../StorageManager/utils";

class AsperaLinkFileSizeModal extends Component {
    state = {
        fileSizeValue: null
    };

    handleSave = () => {
        const {onSave} = this.props;
        const {fileSizeValue} = this.state;
        onSave(fileSizeValue);
    };

    handleClose = () => {
        const {onClose} = this.props;
        onClose();
    };

    componentWillUnmount() {
        this.handleClose();
    }

    handleChangeFileSize = event => {
        this.setState({ fileSizeValue: event.target.value });
    };

    render() {
        const {fileSizeValue} = this.state;
        return (
            <RndModal
                show={true}
                onHide={this.handleClose}
                backdrop="static"
                enforceFocus={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Aspera Download File Size</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="container-fluid">
                        <ControlLabel>Enter size in bytes:</ControlLabel>
                        <FormControl
                            value={fileSizeValue}
                            onChange={this.handleChangeFileSize}
                            onBlur={this.handleChangeFileSize}
                        />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button bsStyle="primary" onClick={this.handleSave}>Save</Button>
                    <Button bsStyle="default" onClick={this.handleClose}>Cancel</Button>
                </Modal.Footer>
            </RndModal>
        );
    }
}

AsperaLinkFileSizeModal.propTypes = {
    onSave: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
};

export default AsperaLinkFileSizeModal;
