import React from 'react';
import {Button, Modal} from "react-bootstrap";
import PropTypes from "prop-types";
import RndModal from "../../../../components/modals/RndModal";
import Loader from "../../../../components/Loader";
import {
    executeHdrReportPortalAction,
    getHdrReportParametersForFile, switchHdrReportModal
} from "../../actions";
import {showError} from "../../../../layouts/actions";
import {getErrorMessage} from "../../../../utils";
import {connect} from "react-redux";
import Select from 'react-select';
import styled from "styled-components";

class HdrReportModal extends React.PureComponent {
    state = {
        showLoader: false,
        showFileParams: false,
        colorSpace: null,
        colorSpaceIsValid: false,
        frameRate: null,
        frameRateIsValid: false,
        frameRateFilename: null,
        frameRateMismatch: false,
        aspectRatio: null,
        aspectRatioList: [],
        aspectRatioValidOptions: [],
        selectedRatio: null,
    }

    handleClose = () => {
        const {dispatch} = this.props;
        dispatch(switchHdrReportModal(false));
    };

    handleSubmit = () => {
        const {dispatch} = this.props;
        const {aspectRatio, colorSpace, frameRate} = this.state;
        dispatch(executeHdrReportPortalAction(colorSpace, frameRate, aspectRatio)).always(() => this.handleClose());
    }

    componentDidMount() {
        const {dispatch, contentItem} = this.props;

        this.setState({
            showLoader: true
        });

        dispatch(getHdrReportParametersForFile(contentItem.fsRootID, contentItem.contentID)).then((res) => {
            const ratios = res.aspect_ratios || [];
            const areAllParametersCorrect = ratios.length === 1 && ratios[0].is_valid && res.color_space_is_valid && res.frame_rate_is_valid && res.frame_rate_not_mismatch;
            if (areAllParametersCorrect) {
                // run HDR report action without ask user
                const colorSpace = res.color_space;
                const frameRate = res.frame_rate_mediainfo;
                dispatch(executeHdrReportPortalAction(colorSpace, frameRate, ratios[0].aspect_ratio)).always(() => this.handleClose());
            } else {
                this.setState({
                    showFileParams: true,
                    aspectRatioList: ratios,
                    aspectRatioValidOptions: res.aspect_ratio_valid_options,
                    colorSpace: res.color_space,
                    colorSpaceIsValid: res.color_space_is_valid,
                    frameRate: res.frame_rate_mediainfo,
                    frameRateIsValid: res.frame_rate_is_valid,
                    frameRateFilename: res.frame_rate_filename,
                    frameRateMismatch: res.frame_rate_mismatch,
                });
            }
        }).catch(e => {
            dispatch(showError('Failed get aspect ratio for selected file. ' + getErrorMessage(e)));
        }).always(() => {this.setState({showLoader: false})});
    }

    componentWillUnmount() {
        this.handleClose();
    }

    isSelectedRatio = (row) => {
        const {selectedRatio} = this.state;
        return selectedRatio && selectedRatio.aspect_ratio === row.aspect_ratio;
    }

    handleOnSelectRatio = (checked, row) => {
        this.setState({
            selectedRatio: (checked ? row : null),
            aspectRatio: (checked ? row.is_valid ? row.aspect_ratio : row.alternate_ratio : null)
        });
    };

    onChangeAspectRatio = (selectedAspectRatio) => {
        if (selectedAspectRatio === null) {
        this.setState({
                aspectRatio: null,
                selectedRatio: null,
        });
            return;
    }
        this.setState({
            aspectRatio: selectedAspectRatio.value,
        });
    };

    render() {
        const {showFileParams, aspectRatioList, aspectRatio, colorSpace, colorSpaceIsValid, frameRate, frameRateIsValid} = this.state;
        const {contentItem} = this.props;

        const aspectRatioValidOptions = this.state.aspectRatioValidOptions.map(a => {
            return {
                label: a,
                value: a
            }
        });
        const allowCreateHdrReport = aspectRatio && colorSpaceIsValid && frameRateIsValid;

        return (
            <>
                <RndModal
                    show={true}
                    onHide={this.handleClose}
                    minHeight={300}
                    backdrop={false}
                    enforceFocus={false}
                    bsSize="large"
                >
                    <Modal.Header closeButton>
                        <Modal.Title>HDR Report</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {this.state.showLoader ? <div><Loader/></div> : null}
                        <ModalContainerStyled>
                            {showFileParams &&
                                <>
                                    <div>The selected file - {contentItem.name}</div>
                                    <br/>
                                    <div><b>Aspect ratio</b>:{aspectRatioList.length === 0 ? ' not found': null}</div>
                                    {aspectRatioList.length > 0 ?
                                        <>
                                        <table className="aspect-ratio-table">
                                        <tbody>
                                          <tr>
                                            <th className="checkbox-table-cell"/>
                                            <th className="aspect-ratio-cell">Value</th>
                                            <th className="frame-percents-cell">Frame Percents</th>
                                            <th className="frame-percents-cell"/>
                                              <th className="frame-percents-cell"/>
                                          </tr>
                                        {aspectRatioList.map(item => (
                                          <tr key={item.aspect_ratio}>
                                            <td className="checkbox-table-cell">
                                              <input
                                                type="checkbox"
                                                checked={this.isSelectedRatio(item)}
                                                onChange={(event) => this.handleOnSelectRatio(event.currentTarget.checked, item)}
                                              />
                                            </td>
                                            <td>{item.aspect_ratio || '-'}</td>
                                            <td>{item.frame_percents.join()}</td>
                                            <td>{item.is_valid ? null : 'Found value is invalid, but you can use the alternate ratio: ' + item.alternate_ratio}</td>
                                            <td>{item.is_mismatch ? 'Mismatch - filename contains another ratio: ' + item.aspect_ratio_filename : null}</td>
                                          </tr>
                                        ))}
                                        </tbody>
                                        </table>
                                        </> : <div>We could not automatically determine the aspect ratio value</div>
                                    }
                                    <br/>
                                    <div className="ratio-select">
                                        <span>Please select:</span>
                                        <div>
                                            <Select
                                        value={aspectRatio}
                                                options={aspectRatioValidOptions}
                                                onChange={this.onChangeAspectRatio}
                                    />
                                        </div>
                                    </div>
                                    <br/>
                                    <div><b>Frame rate</b>: {frameRate || 'not found'}{frameRateIsValid ? '' : (<><span> - </span><span className="report-error">no matching hot folder</span></>)}</div>
                                    {this.state.frameRateMismatch && <span>Mismatch - filename contains another frame rate: {this.state.frameRateFilename}</span>}
                                    <br/>
                                    <div><b>Color space</b>: {colorSpace || 'not found'}{colorSpaceIsValid ? '' : (<><span> - </span><span className="report-error">no matching hot folder</span></>)}</div>
                                </>
                            }
                        </ModalContainerStyled>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button bsStyle="primary" disabled={!allowCreateHdrReport} onClick={this.handleSubmit}>Generate HDR report</Button>
                        <Button bsStyle="default" onClick={this.handleClose}>Cancel</Button>
                    </Modal.Footer>
                </RndModal>
            </>
        );
    }
}

const ModalContainerStyled = styled.div`
    span.report-error {
      color: red;
    }

  div.ratio-select {
    display: flex;
    align-items: center;

    > div {
      width: 200px;
      margin-left: 5px;
    }
  }

  table tr {
    td, th {
      border: 1px solid grey;
      padding: 4px;
      word-break: break-word;
    }
  }
`;

HdrReportModal.propTypes = {
    dispatch: PropTypes.func.isRequired,
    contentItem: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
    const {checkedItems} = state.storageManager;
    return {
        contentItem: checkedItems[0]
    };
}

export default connect(
    mapStateToProps
)(HdrReportModal);
