import {USER_SET_IS_LOGOUT} from '../../pages/Login/actions';
import {
  AUTO_DOWNLOADS_SET_LOADING,
  AUTO_DOWNLOADS_SET_ASSETS_STATUSES,
  AUTO_DOWNLOADS_GET_EMAILS,
  AUTO_DOWNLOADS_CHANGE_PAGE_SIZE,
  AUTO_DOWNLOADS_CHANGE_CURRENT_PAGE,
  AUTO_DOWNLOADS_CHANGE_FILTER_VALUE,
  AUTO_DOWNLOADS_LINK_TO_MEMFIS_WO_IS_SAVED,
  MAM_UPLOADS_LINK_TO_MEMFIS_WO_IS_SAVED,
  MAM_UPLOADS_FILES_SHOW,
  AUTO_DOWNLOADS_SHOW_SELECT_FOLDER_MODAL,
  AUTO_DOWNLOADS_MANUAL_UPLOAD_IS_DONE,
  DOWNLOAD_MNG_CHANGE_SELECTED_ITEMS,
  DOWNLOAD_MNG_CLEAR_ALL,
  DOWNLOAD_MNG_SET_ASSET_DATA,
  DOWNLOAD_MNG_INGEST_SHOW_MODAL,
  AUTO_DOWNLOADS_COMPLETE_DOWNLOAD_FOR_MULTIPLE_WOS,
  AUTO_DOWNLOADS_SET_NOT_LOCKED_ROOTS,
  AUTO_DOWNLOADS_CHANGE_LINK_FIELD,
  DOWNLOAD_MNG_SHOW_DELIVERY_MODAL,
  AUTO_DOWNLOADS_FILTERS_PRESETS_SET,
  AUTO_DOWNLOADS_FILTERS_PRESETS_SELECT,
  AUTO_DOWNLOADS_FILTERS_PRESETS_SAVE,
  AUTO_DOWNLOADS_FILTERS_PRESETS_SET_AS_DEFAULT,
  AUTO_DOWNLOADS_FILTERS_PRESETS_DELETE,
  AUTO_DOWNLOADS_FILTERS_PRESETS_SET_ADVANCED_MODE,
  AUTO_DOWNLOADS_GET_EMAILS_UPDATE_LINK
} from './actions';
import {
  /*FILTER_DOWNLOAD_TYPE_AUTO, FILTER_DOWNLOAD_TYPE, */FILTER_DAYS_BACK_FROM, FLAG_FINAL_MOVE_WITHOUT_WO,/* FILTER_WO_MEMFIS,
  FILTER_HIDE_NAM_ASSETS, FILTER_HIDE_COMPLETED, FILTER_OFFICE_FLAG, FILTER_SEARCH, FILTER_STATUS, FILTER_CLIENT,*/
  getDefaultsOfFilters
} from './constants';
import {produce} from "immer";
import {isEqualsItems, calcMaxPriorityAndMarkLinksToChangePriority} from './utils';
import _ from 'lodash';

const applyPreset = (selectedPreset, updatedState) => {
  if (selectedPreset) {
    const {filters} = selectedPreset;
    updatedState.usedFilters = {...getDefaultsOfFilters()};
    Object.keys(updatedState.usedFilters).forEach(key => {
      if (key in filters && filters[key]) {
        if (key === FILTER_DAYS_BACK_FROM) {
          updatedState.usedFilters[key] = {...filters[key]};
        } else {
          updatedState.usedFilters[key] = filters[key];
        }
      }
    });
    updatedState.selectedFiltersPreset = {...selectedPreset, filtersJson: JSON.stringify(updatedState.usedFilters)};
  }
  return updatedState;
};

const initialState = {
  appliedUsedFiltersJson: null,
  appliedWoMemfis: null,
  assetStatuses: null,
  assetStatusesBeforeDownloading: null,
  assetStatusesWithChangePriority: null,
  currentPage: 0,
  data: [],
  fileMakerURL: null,
  filtersPresets: [],
  lastCrawlTS: null,
  lastDownloadTS: null,
  lastPackageTS: null,
  loaders: {
    changeLinkField: false,
    getAssetData: false,
    getAssetStatuses: false,
    getFiltersPresets: false,
    getRootLockStatuses: false,
    getUploadedFiles: false,
    linkToMemfisWO: false,
    manualUpload: false,
    restartAction: false,
    savePreset: false,
    startDeliveryMemfisWO: false,
    table: false,
  },
  manualData: [],
  maxRate: 0,
  memfisWoInDeliveryModal: null,
  minRate: 0,
  notLockedRoots: null,
  pageSize: 100,
  selectedFiltersPreset: null,
  selectedItems: [],
  selectFolderModalItem: null,
  selectFolderModalItems: null,
  uploadData: [],
  uploadFiles: null,
  usedFilters: getDefaultsOfFilters(),
  ingestOperationTypes: null,
  ingestWorkOrder: null,
  isShowIngestModal: false,
  ingestFolder: null,
  ingestItem: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case DOWNLOAD_MNG_CLEAR_ALL:
    case USER_SET_IS_LOGOUT: {
      return {...initialState};
    }

    case AUTO_DOWNLOADS_SET_LOADING: {
      return {
        ...state,
        loaders: {
          ...state.loaders,
          [action.name]: action.loading
        }
      };
    }

    case AUTO_DOWNLOADS_SET_ASSETS_STATUSES: {
      return {
        ...state,
        loaders: {
          ...state.loaders,
          getAssetStatuses: false
        },
        assetStatuses: action.statuses,
        assetStatusesWithChangePriority: action.statusesWithChangePriority,
        assetStatusesBeforeDownloading: action.statusesBeforeDownloading,
      };
    }

    case AUTO_DOWNLOADS_GET_EMAILS: {
      return {
        ...state,
        appliedUsedFiltersJson: JSON.stringify(state.usedFilters),
        currentPage: initialState.currentPage,
        selectedItems: initialState.selectedItems,
        lastCrawlTS: action.lastCrawlTS,
        lastPackageTS: action.lastPackageTS,
        lastDownloadTS: action.lastDownloadTS,
        fileMakerURL: action.fileMakerURL,
        appliedWoMemfis: action.appliedWoMemfis || initialState.appliedWoMemfis,
        data: action.data,
        manualData: action.manualData,
        uploadData: action.uploadData,
        minRate: action.minRate,
        maxRate: action.maxRate
      }
    }

    case AUTO_DOWNLOADS_GET_EMAILS_UPDATE_LINK: {
      const {newLink} = action;
      const newData = state.data.map((item) => {
        if (item.requestID !== newLink.requestID) {
          return item;
        }

        return newLink;
      });

      return {
        ...state,
        data: newData,
        selectedItems: [newLink.links[0]]
      };
    }

    case AUTO_DOWNLOADS_CHANGE_PAGE_SIZE: {
      return {
        ...state,
        pageSize: action.value,
        currentPage: initialState.currentPage,
        selectedItems: initialState.selectedItems
      }
    }
    case AUTO_DOWNLOADS_CHANGE_CURRENT_PAGE: {
      return {
        ...state,
        currentPage: action.page,
        selectedItems: initialState.selectedItems
      }
    }

    case AUTO_DOWNLOADS_CHANGE_FILTER_VALUE: {
      let value = action.value;
      if (Array.isArray(value) && !value.length) {
        value = initialState.usedFilters[action.name];
      }
      return {
        ...state,
        usedFilters: {...state.usedFilters, [action.name]: value}
      }
    }

    case AUTO_DOWNLOADS_LINK_TO_MEMFIS_WO_IS_SAVED: {
      const {memfisWoID, destinationPath, isSelectedItem} = action;

      return produce(state, (draft) => {
        const {uploadData, data} = draft;
        const uploadItem = uploadData.find(i => i.WOMemfis === memfisWoID);
        let items = data.filter(i => isEqualsItems(i, action.item));
        if (items.length !== 1) {
          items = data.filter(i => i.links && i.links.filter(link => isEqualsItems(link, action.item)).length === 1);
        }
        if (items.length === 1) {
          const newIngestStatus = 'BARCODING AND MOVE STARTED';
          const newStatus = 'Barcoding and Move started';
          items[0].WOMemfis = memfisWoID;
          items[0].file_name = destinationPath;
          items[0].status = newStatus;
          items[0].ingest_status = newIngestStatus;
          items[0].ingest_status_advanced = newIngestStatus;
          if (items[0].links) {
            if (items[0].links.length === 1) {
              items[0].links[0].WOMemfis = memfisWoID;
              items[0].links[0].file_name = destinationPath;
              items[0].links[0].status = newStatus;
            } else {
              items[0].links.forEach(link => {
                link.WOMemfis = memfisWoID;
                const lastSegment = (link.file_name || '').split('\\').pop().split('/').pop() || '';
                link.file_name = `${destinationPath}\\${lastSegment}`;
                link.status = newStatus;
              });
            }
            if (uploadItem) {
              items[0].links.push(uploadItem);
              _.remove(uploadData, i => i.upload_id === uploadItem.upload_id);
            }
          }
        }
        draft.loaders.linkToMemfisWO = false;
        draft.selectedItems = isSelectedItem ? initialState.selectedItems : state.selectedItems;
      });
    }

    case MAM_UPLOADS_LINK_TO_MEMFIS_WO_IS_SAVED: {
      const {uploadId, memfisWoID, isSelectedItem} = action;
      return produce(state, (draft) => {
        const {data, uploadData} = draft;
        const autoDlItem = data.find(i => i.WOMemfis === memfisWoID);
        const item = uploadData.find(i => i.upload_id === uploadId);
        if (item) {
          item.WOMemfis = memfisWoID;
          if (autoDlItem && autoDlItem.links) {
            autoDlItem.links.push(item);
            _.remove(uploadData, i => i.upload_id === uploadId);
          }
        }
        draft.loaders.linkToMemfisWO = false;
        draft.selectedItems = isSelectedItem ? initialState.selectedItems : state.selectedItems;
      });
    }

    case AUTO_DOWNLOADS_SHOW_SELECT_FOLDER_MODAL: {
      return {
        ...state,
        selectFolderModalItem: action.item || initialState.selectFolderModalItem,
        ingestItem: action.item || state.ingestItem,
        selectFolderModalItems: action.items || initialState.selectFolderModalItems
      };
    }

    case AUTO_DOWNLOADS_MANUAL_UPLOAD_IS_DONE: {
      const {WOMemfis, package_id, link_id, is_parallel, folderKey} = action;

      return produce(state, (draft) => {
        const {data} = draft;
        if (is_parallel) {
          const item = data.find(i => i.parallel_ingest && i.parallel_ingest.links && i.parallel_ingest.links.find(link =>
            link.link_id === link_id && link.package_id === package_id && link.WOMemfis === WOMemfis));
          if (item) {
            item.parallel_ingest.links.filter(link =>
              link.link_id === link_id && link.package_id === package_id && link.WOMemfis === WOMemfis).forEach(link => {
              link.folderKey = folderKey;
            });
          }
        } else {
          const item = data.find(i => i.links && i.links.find(link =>
            link.link_id === link_id && link.package_id === package_id && link.WOMemfis === WOMemfis));
          if (item) {
            item.links.filter(link =>
              link.link_id === link_id && link.package_id === package_id && link.WOMemfis === WOMemfis).forEach(link => {
              link.folderKey = folderKey;
            });
          }
        }
      });
    }

    case DOWNLOAD_MNG_CHANGE_SELECTED_ITEMS: {
      return {
        ...state,
        selectedItems: [...action.items || []]
      };
    }

    case DOWNLOAD_MNG_SET_ASSET_DATA: {
      return produce(state, (draft) => {
        const {selectedItems} = draft;
        selectedItems.forEach(item => {
          if (item.WOMemfis === action.WOMemfis) {
            item.assetData = action.data;
          }
        });
      });
    }

    case DOWNLOAD_MNG_INGEST_SHOW_MODAL: {
      return {
        ...state,
        isShowIngestModal: action.show || initialState.isShowIngestModal,
        ingestOperationTypes: action.operationTypes || initialState.ingestOperationTypes,
        ingestWorkOrder: action.workOrder || initialState.ingestWorkOrder,
        ingestFolder: action.folder || initialState.ingestFolder,
        ingestItem: action.item || initialState.ingestItem,
      };
    }

    case MAM_UPLOADS_FILES_SHOW: {
      return {
        ...state,
        uploadFiles: action.files || initialState.uploadFiles
      };
    }

    case AUTO_DOWNLOADS_COMPLETE_DOWNLOAD_FOR_MULTIPLE_WOS: {
      return produce(state, (draft) => {
        const {data} = draft;
        let items = data.filter(i => isEqualsItems(i, action.item));
        if (items.length !== 1) {
          items = data.filter(i => i.links && i.links.filter(link => isEqualsItems(link, action.item)).length === 1);
        }
        if (items.length === 1) {
          items[0].flags = (items[0].flags || 0) | FLAG_FINAL_MOVE_WITHOUT_WO;
          if (items[0].links) {
            if (items[0].links.length === 1) {
              items[0].links[0].flags = (items[0].links[0].flags || 0) | FLAG_FINAL_MOVE_WITHOUT_WO;
            } else {
              items[0].links.forEach(link => {
                link.flags = (link.flags || 0) | FLAG_FINAL_MOVE_WITHOUT_WO;
              });
            }
          }
        }
        draft.loaders.completeDownloadForMultipleWOs = false;
        draft.selectedItems = action.isSelectedItem ? initialState.selectedItems : state.selectedItems;
      });
    }

    case AUTO_DOWNLOADS_SET_NOT_LOCKED_ROOTS: {
      return produce(state, (draft) => {
        draft.loaders.getRootLockStatuses = false;
        draft.notLockedRoots = action.notLockedRoots || initialState.notLockedRoots;
      });
    }

    case AUTO_DOWNLOADS_CHANGE_LINK_FIELD: {
      const {value, name} = action;
      return produce(state, (draft) => {
        const {selectedItems, data} = draft;
        try {
          let items = data.filter(i => isEqualsItems(i, action.item));
          if (items.length === 1) {
            items[0][name] = value;
          } else {
            items = data.filter(i => i.links && i.links.filter(link => isEqualsItems(link, action.item)).length === 1);
            if (items.length === 1) {
              const prevValue = items[0].links.filter(link => isEqualsItems(link, action.item))[0][name];
              items[0].links.filter(link => isEqualsItems(link, action.item))[0][name] = value;
              if (name === 'Priority') {
                if (typeof items[0][name] !== 'number' || value > items[0][name]) {
                  items[0][name] = value;
                } else if (items[0][name] === prevValue) {
                  items[0][name] = calcMaxPriorityAndMarkLinksToChangePriority(items[0].links, state.assetStatusesBeforeDownloading);
                }
              }
            }
          }
          if (selectedItems.length === 1 && isEqualsItems(action.item, selectedItems[0])) {
            selectedItems[0][name] = value;
          }
        } catch (ignored) {/**/}
        draft.loaders.changeLinkField = false;
      });
    }

    case DOWNLOAD_MNG_SHOW_DELIVERY_MODAL: {
      return {
        ...state,
        memfisWoInDeliveryModal: action.woMemfisID || initialState.memfisWoInDeliveryModal
      };
    }

    case AUTO_DOWNLOADS_FILTERS_PRESETS_SET: {
      const {filtersPresets, storedPresetId, woMemfisFromUrl} = action;
      const selectedPreset =
        (woMemfisFromUrl ? filtersPresets.find(p => p.label === woMemfisFromUrl) : null) ||
        (!isNaN(storedPresetId) ? filtersPresets.find(p => p.value === storedPresetId) : null) ||
        filtersPresets.find(p => p.is_default) || (filtersPresets.length ? filtersPresets[0] : null);
      const updState = applyPreset(selectedPreset, {filtersPresets});
      return {
        ...state,
        loaders: {
          ...state.loaders,
          getFiltersPresets: false
        },
        ...updState
      };
    }

    case AUTO_DOWNLOADS_FILTERS_PRESETS_SELECT: {
      const {preset} = action;
      return {
        ...state,
        ...applyPreset(preset, {})
      };
    }

    case AUTO_DOWNLOADS_FILTERS_PRESETS_SAVE: {
      const {preset, isNew} = action;
      return produce(state, (draft) => {
        const {filtersPresets} = draft;
        if (isNew) {
          filtersPresets.push(preset);
        } else {
          filtersPresets[filtersPresets.findIndex(p => p.value === preset.value)] = preset;
        }
        filtersPresets.sort((a, b) => a.label.localeCompare(b.label));
        draft.loaders.savePreset = false;
        Object.entries(applyPreset(preset, {filtersPresets})).forEach(([prop, val]) => {
          draft[prop] = val;
        });
      });
    }

    case AUTO_DOWNLOADS_FILTERS_PRESETS_SET_ADVANCED_MODE: {
      const {isAdvancedMode} = action;
      return produce(state, (draft) => {
        const currentPresetId = draft.selectedFiltersPreset.value;
        const {filtersPresets} = draft;
        filtersPresets.forEach(p => {
          if (p.value === currentPresetId) {
            p.is_advanced = isAdvancedMode;
          }
        });
        draft.loaders.savePreset = false;
        draft.selectedFiltersPreset = {
          ...draft.selectedFiltersPreset,
          is_advanced: isAdvancedMode
        };
      });
    }

    case AUTO_DOWNLOADS_FILTERS_PRESETS_SET_AS_DEFAULT: {
      return produce(state, (draft) => {
        const defaultPresetId = draft.selectedFiltersPreset.value;
        const {filtersPresets} = draft;
        filtersPresets.forEach(p => {
          p.is_default = p.value === defaultPresetId;
        });
        draft.loaders.savePreset = false;
        draft.selectedFiltersPreset = {
          ...draft.selectedFiltersPreset,
          is_default: true
        };
      });
    }

    case AUTO_DOWNLOADS_FILTERS_PRESETS_DELETE: {
      const {presetIDs} = action;
      return {
        ...state,
        loaders: {
          ...state.loaders,
          savePreset: false
        },
        filtersPresets: [...state.filtersPresets].filter(p => !presetIDs.includes(p.value))
      };
    }

    default: {
      return state;
    }
  }
}
